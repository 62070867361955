<template>
  <figure class="chart animate">
    <svg role="img" xmlns="http://www.w3.org/2000/svg">
      <!--<title>[title here]</title>-->
      <!--<desc>[long description here]</desc>-->
      <circle class="circle-background" />
      <circle
        v-show="percent > 0"
        class="circle-foreground"
        :style="'stroke-dashoffset: ' + normalizedPercent" />
    </svg>
    <figcaption v-html="circleText"></figcaption>
  </figure>
</template>

<script>
export default {
  name: 'CircleChart',
  props: ['percent', 'circleText'],
  computed: {
    normalizedPercent() {
      // console.log(this.percent, ((100 - this.percent) * 170) / 100)
      return -((100 - this.percent) * 170) / 100
    }
  }
}
</script>

<style scoped>
.chart {
  width: 60px;
  height: 60px;
  margin: 0;
  position: relative;
}

.chart svg {
  width: 100%;
  height: 100%;
}
.chart svg .circle-background,
.chart svg .circle-foreground {
  r: 27.5px;
  cx: 50%;
  cy: 50%;
  fill: none;
  stroke: rgba(255, 255, 255, 0.16);
  stroke-width: 5px;
}
.chart svg .circle-foreground {
  stroke: #8bdea6;
  stroke-dasharray: 170 170;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
}
.chart figcaption {
  display: inline-block;
  width: 100%;
  height: 3rem;
  overflow: hidden;
  text-align: center;
  color: #ffffff;
  position: absolute;
  top: calc(50% - 1rem);
  left: 0;
  font-size: 15px;
  line-height: 1;
}
/*.chart figcaption:after {*/
/*display: inline-block;*/
/*content: "0%\a1%\a2%\a3%\a4%\a5%\a6%\a7%\a8%\a9%\a10%\a11%\a12%\a13%\a14%\a15%\a16%\a17%\a18%\a19%\a20%\a21%\a22%\a23%\a24%\a25%\a26%\a27%\a28%\a29%\a30%\a31%\a32%\a33%\a34%\a35%\a36%\a37%\a38%\a39%\a40%\a41%\a42%\a43%\a44%\a45%\a46%\a47%\a48%\a49%\a50%\a51%\a52%\a53%\a54%\a55%\a56%\a57%\a58%\a59%\a60%\a61%\a62%\a63%\a64%\a65%\a66%\a67%\a68%\a69%\a70%\a71%\a72%\a73%\a74%\a75%\a76%\a77%\a78%\a79%\a80%\a81%\a82%\a83%\a84%\a85%\a86%\a87%\a88%\a89%\a90%\a91%\a92%\a93%\a94%\a95%\a96%\a97%\a98%\a99%\a100%\a";*/
/*white-space: pre;*/
/*font-size: 1rem;*/
/*line-height: 1rem;*/
/*}*/
</style>
