<template>
  <div :class="backgroundClasses" class="h-5 inline-block inline-flex flex-col px-2 justify-center">
    <p :class="textClasses" class="font-semibold">
      <slot/>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ZTag',
  props: {
    size: {
      type: String,
      default: () => 'medium'
    },
    type: {
      type: String,
      default: () => 'primary'
    },
    rounded: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    backgroundClasses() {
      let c = []

      switch (this.size) {
        case 'small': {
          c = [...c, 'px-3 py-2']
          break
        }
        case 'medium': {
          c = [...c, 'px-4 py-3']
          break
        }
        case 'large': {
          c = [...c, 'px-5 py-4']
          break
        }
      }

      switch (this.type) {
        case 'primary': {
          c = [...c, 'bg-primary']
          break
        }
        case 'secondary': {
          c = [...c, 'bg-secondary']
          break
        }
        case 'info': {
          c = [...c, 'bg-info']
          break
        }
        case 'success': {
          c = [...c, 'bg-green']
          break
        }
        case 'warning': {
          c = [...c, 'bg-warning']
          break
        }
        case 'danger': {
          c = [...c, 'bg-danger']
          break
        }
        case 'purple': {
          c = [...c, 'bg-purple-100']
          break
        }
      }

      c = [...c, this.rounded ? 'rounded-full' : 'rounded-sm']

      return c
    },

    textClasses() {
      let c = []

      switch (this.size) {
        case 'small': {
          c = [...c, 'text-xs']
          break
        }
        case 'medium': {
          c = [...c, 'text-base']
          break
        }
        case 'large': {
          c = [...c, 'text-lg']
          break
        }
      }

      switch (this.type) {
        case 'info':
        case 'danger':
        case 'warning':
        case 'primary':
        case 'success': {
          c = [...c, 'text-white']
          break
        }
        case 'secondary': {
          c = [...c, 'text-white']
          break
        }
        case 'purple': {
          c = [...c, 'text-purple-900']
          break
        }
      }

      return c
    }
  }
}
</script>

<style scoped>

</style>
