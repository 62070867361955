<template>
  <div class="text-white p-3 rounded-md bg-black bg-opacity-20 whitespace-pre-wrap text-left">
    {{ messageData.text }}
  </div>
</template>

<script>
export default {
  name: 'SystemMessage',
  props: {
    messageData: {
      type: Object,
      default() {
        return { text: 'Hello from the system!', messageType: 'info' }
      }
    },
    localizationKeys: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>
