<!--suppress JSUnresolvedVariable -->
<template>
  <div is="li" @click="selectItem"
      class="list-box-item text-gray-900 dark:text-white select-none relative py-2 pl-3 pr-3 cursor-pointer border-gray-200"
      :class="{' bg-primary bg-opacity-10 ': isSelected && highlightBackground}"
      :id="'lb-' + this.$uuid()" role="option" @mouseenter="itemMouseEnter" @mouseleave="itemMouseLeave">
    <div class="font-normal block w-full flex justify-between items-center"
         :class="{'text-primary font-bold': isSelected}">
        <span class="inline-block flex-grow-0 me-2 w-4">
          <span v-if="!customCheckbox">
            <span v-if="isSelected" class="icon-check-mark-button"/>
          </span>
          <span v-else>
            <span v-if="isSelected">
              <slot name="activeCheckbox"></slot>
            </span>
            <span v-else>
              <slot name="notActiveCheckbox"></slot>
            </span>
          </span>
        </span>
      <span class="flex-grow">
          <slot/>
        </span>
    </div>
  </div>
</template>

<script>
import { shallowEqual } from '../utils/helpers'

export default {
  name: 'ZListBoxItem',
  props: {
    value: {
      type: [String, Number, Object]
    },
    isSelectAll: {
      type: Boolean,
      default: false
    },
    customCheckbox: {
      type: Boolean,
      default: false
    },
    highlightBackground: {
      type: Boolean,
      default: false
    }
  },
  inject: ['parentInstance'],
  data: () => ({
    isHovering: false,
    isSelectAllSelected: false
  }),
  computed: {
    isSelected() {
      if (this.isSelectAll) {
        return this.isSelectAllSelected
      } else if (this.parentInstance.multiSelect) {
        return this.parentInstance.selected.findIndex(x => shallowEqual(x, this.value)) > -1
      } else {
        return this.parentInstance.selected === this.value
      }
    }
  },
  methods: {
    selectItem() {
      this.parentInstance.selectItem(this)
    },
    itemMouseEnter(el) {
      el.target.classList.add('text-primary', 'bg-pink-200')
      el.target.classList.remove('text-gray-900')
    },
    itemMouseLeave(el) {
      el.target.classList.add('text-gray-900')
      el.target.classList.remove('text-primary', 'bg-pink-200')
    }
  },
  mounted() {
    this.parentInstance.registerItem(this)
  },
  beforeDestroy() {
    this.parentInstance.unregisterItem(this)
  }
}
</script>

<style scoped>
.list-box-item:not(:last-child) {
  border-bottom: 1px solid #e3e3e3;
}
</style>
