<template>
  <div style="min-height: 325px">
    <div class="width-100 text-center" v-if="this.selectedManufacturer !== 'All'">
      <a class="closable-filter" @click="clearManufacturer"
        >{{ this.selectedManufacturer }} <i class="fa fa-times-circle"></i
      ></a>
    </div>
    <div class="selector filter">
      <template v-for="category in Object.keys(categories)">
        <input
          checked="checked"
          type="radio"
          :id="category + _uid"
          v-model="selectedCategory"
          :value="category" />
        <label class="drinkcard-cc" :for="category + _uid">
          <i class="fa fa-2x" :class="[categories[category].icon]" />
          <small style="display: block"> {{ t(category) }} </small>
        </label>
      </template>
    </div>

    <div class="swiper-container" v-carousel :dir="language === 'ar' ? 'rtl' : 'ltr'">
      <div class="swiper-button-prev" slot="button-prev" @click="prev()"></div>
      <div
        class="swiper-wrapper"
        :class="computeFilters.prods.length === 0 ? '' : 'margin-bottom-2'">
        <div
          class="swiper-slide item text-center"
          v-for="(product, index) in computeFilters.prods"
          :key="index">
          <h5>{{ product.name }}</h5>
          <img
            alt="Product"
            class="margin-bottom-1"
            :src="'https://www.kw.zain.com/' + product.image" />
          <div
            v-if="product.ordertype !== 'Info' && product.ordertype !== 'Pre Order'"
            class="product-bg">
            <p class="left" :style="language === 'ar' ? 'text-align: right' : ''">
              <span> Starting From<br /></span>
              <span
                >KD <em>{{ product.startprice }}</em></span
              >
              <br />
              <span style="position: relative; top: 5px" v-if="product.ordertype !== 'Ecard'"
                >Monthly</span
              >
            </p>
            <a
              target="_blank"
              @click="$ga.event('ZOSLink', product.name)"
              :href="getBuyUrl(product.buyurl)"
              data-button-type="choosenDevice"
              title="Buy Now"
              class="btn right"
              >Buy Now</a
            >
          </div>
          <div v-else class="product-bg">
            <a
              style="width: 80%"
              target="_blank"
              @click="$ga.event('ZOSLink', product.name)"
              :href="getBuyUrl(product.buyurl)"
              data-button-type="choosenDevice"
              title="Buy Now"
              class="btn right padding-horizontal-1">
              {{ language === 'en' ? product.buynow : product.buynowar }}
            </a>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <div v-if="computeFilters.prods.length === 0" class="item text-center">No Items Found</div>
    <div class="swiper-button-next" slot="button-next" @click="next()"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/swiper-bundle.min'
import 'swiper/swiper-bundle.css';

export default {
  name: 'ProductsCell',
  props: ['products', 'entities', 'localisationKeys', 'language'],
  data() {
    return {
      selectedCategory: 'Smart Phones',
      selectedManufacturer: 'All',
      added: false,
      categories: {
        'Smart Phones': {
          en: 'Smart Phones',
          ar: 'الهواتف الذكية',
          icon: 'fa-mobile-phone'
        },
        'SIM Card': {
          en: 'eeZee SIM Card',
          ar: 'شريحة إيزي للدفع المسبق',
          icon: 'fa-id-card-o'
        },
        'Internet Devices': {
          en: 'Internet Devices',
          ar: 'اجهزة الانترنت',
          icon: 'fa-wifi'
        },
        eCards: {
          en: 'eCards',
          ar: 'البطاقات الإلكترونية',
          icon: 'fa-credit-card'
        },
        // Health: {
        //   en: 'Health',
        //   ar: 'الصحة واللياقة',
        //   icon: 'fa-heart'
        // },
        'Laptops and Tablets': {
          en: 'Laptops and Tablets',
          ar: 'أجهزة لوحية ولابتوب',
          icon: 'fa-tablet'
        },
        'Wearables and Headsets': {
          en: 'Wearables and Headsets',
          ar: 'الساعات الذكية والسماعات',
          icon: 'fa-headphones'
        },
        'Smart Home': {
          en: 'Smart Home',
          ar: 'أجهزة المنزل',
          icon: 'fa-home'
        },
        Gaming: {
          en: 'Gaming',
          ar: 'أجهزة الترفيه والألعاب',
          icon: 'fa-gamepad'
        },
        'Smart TV’s': {
          en: 'Smart TV’s',
          ar: 'التلفزيونات الذكية',
          icon: 'fa-tv'
        },
        Accessories: {
          en: 'Accessories',
          ar: 'الاكسسوارات',
          icon: 'fa-sun-o'
        }
      }
    }
  },
  updated: function () {
    this.added = true
  },
  computed: {
    computeFilters() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.added = false
      return { prods: this.computedProducts, mans: this.computedManufacturers }
    },
    computedProducts() {
      let newArr = this.products

      if (this.selectedCategory !== 'All')
        newArr = newArr.filter((x) => this.selectedCategory?.toLowerCase() === x.type?.toLowerCase())
      if (this.selectedManufacturer !== 'All')
        newArr = newArr.filter((x) => this.selectedManufacturer?.toLowerCase() === x.manufacturer?.toLowerCase())
      newArr = newArr.sort((a, b) => (a.ordertype !== 'Pre Order') - (b.ordertype !== 'Pre Order'))
      newArr = newArr.sort((a, b) => parseFloat(a.priority) - parseFloat(b.priority))
      return newArr
    },
    computedManufacturers() {
      return this.products.map((x) => x.manufacturer).filter((v, i, a) => a.indexOf(v) === i)
    }
  },
  mounted: function () {
    console.log('entities', this.entities)
    for (let i = 0; i < this.entities.length; i++) {
      if (this.entities[i].entity === 'DeviceType') {
        this.selectedCategory = this.entities[i].option
      }

      if (this.entities[i].entity === 'DeviceManufacturer') {
        this.selectedManufacturer = this.entities[i].option
      }
    }
    console.log(this.products)
    this.added = true
  },
  directives: {
    carousel: {
      inserted: function (el) {
        // eslint-disable-next-line no-unused-vars
        const swiper = new Swiper(el, {
          pagination: {
            el: el.childNodes[2],
            clickable: true,
            dynamicBullets: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          spaceBetween: 10,
          freeMode: false,
          mousewheel: true,
          grabCursor: true,
          observer: true,
          breakpointsBase: 'container',
          breakpoints: {
            480: {
              slidesPerView: 1
            },
            800: {
              slidesPerView: 2
            },
            2000: {
              slidesPerView: 3
            }
          }
        })
      },
      componentUpdated: function (el) {
        el.swiper.slideTo()
      }
    }
  },
  methods: {
    clearManufacturer() {
      this.selectedManufacturer = 'All'
    },
    getBuyUrl(buyUrl) {
      if (buyUrl.startsWith('http')) return buyUrl
      return (
        (this.language !== 'ar' ? 'https://kw.zain.com/en/' : 'https://kw.zain.com/ar/') + buyUrl
      )
    },
    t(key) {
      return this.categories?.[key]?.[this.language] ?? key
    }
  }
}
</script>

<style scoped>
.item img {
  width: auto;
  height: 170px;
  max-width: 100%;
  border: 0;
  margin: 0 auto;
}

img {
  vertical-align: top;
}

.item {
  height: 21em;
  border-radius: 5px;
  padding-top: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-bg {
  height: 66px;
  padding: 5px;
  background-color: #ffd500;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.product-bg em {
  display: inline-block;
  font-size: 25px;
  font-style: normal;
  font-weight: bold;
  /* margin-right: 20px; */
  margin-top: 3px;
}

.product-bg a.btn {
  margin-top: 10px;
  border: 1px solid black;
  color: black;
  display: inline-block;
  font-size: 11pt;
  font-weight: bold;
  padding: 10px 0;
  text-transform: uppercase;
  width: 90px;
  text-align: center;
  vertical-align: top;
  border-radius: 5px;
}

.product-bg p {
  font-size: 10pt;
  text-align: left;
  width: 50%;
  margin: 0;
  display: inline-block;
  line-height: 1;
}

.filter label {
  display: inline-block;
  /*color: white;*/
  background-color: rgba(255, 255, 255, 0.27);
  border-radius: 5px;
  margin: 5px 5px;

  /*padding: 12px 3px 0;*/
  color: white;
}

.filter i {
  line-height: 1.4;
  font-size: 2.4em;
}

.filter {
  text-align: center;
  margin: 0 -5px 5px;
  /*max-width: 400px;*/
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: space-around;
}

.selector input {
  margin: 0;
  /*padding: 0 5px 0;*/
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
}

.selector input:active + .drinkcard-cc i {
}

.selector input:checked + .drinkcard-cc {
  background-color: #d02486;

  /*border: 1px solid deeppink;*/
  /*font-size: 12px;*/
  /*font-weight: bold;*/
}

.drinkcard-cc {
  /*font-size: 0;*/
  /*height: 70px;*/
  min-width: 50px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  transition: all 400ms ease-out;
  /*border: 1px solid #e7e7e7;*/
  vertical-align: top;
  font-size: 10px;
  padding: 3px 5px 0;
  flex-grow: 1;
}

.drinkcard-cc:hover {
  /*border: 1px solid gray;*/
}

label img {
  width: 25px;
}

small {
  font-size: 115%;
  text-transform: uppercase;
}

.gap {
  flex: 0 0 10px;
}

.closable-filter {
  margin: 5px auto;
  display: inline-block;
  background-color: #b7b5b5;
  color: white;
  border-radius: 14px;
  padding: 4px 10px 1px;
}

.closable-filter i {
  position: relative;
  top: 2px;
  font-size: 1.1em;
}
</style>
