<!--suppress NonAsciiCharacters -->
<template>
  <div>
    <div
      v-if="isNoDataFound"
      :key="1"
      class="text-center w-full bg-white text-black rounded-md h-40 flex flex-col justify-center">
      {{ $t('NoDataFound', language, strings) }}
    </div>
    <div v-else class="flex flex-col items-start gap-y-3" :dir="language === 'ar' ? 'rtl' : 'ltr'">
      <!-- Download bill of specified month -->
      <div class="w-full bg-white text-black flex flex-col justify-between rounded-md">
        <div class="pt-3 text-start px-3">
          {{
            language === 'en'
              ? `${parsedDate.startOf('month').locale(language).format('MMMM')}'s Bill`
              : `فاتورة ${parsedDate.startOf('month').locale(language).format('MMMM')}`
          }}
        </div>
        <div class="p-3 flex flex-wrap gap-3 justify-center">
          <z-button
            class="flex-1"
            @click="onClickGetBill()"
            size="small"
            style="min-width: 80px"
            outlined>
            {{ $t('DownloadBill', language, strings) }}
          </z-button>
        </div>
      </div>

      <!-- Prompt user to get previous bills -->
      <!-- Hide if user specified date -->
      <div
        v-if="!isDateProvided"
        class="w-full bg-white text-black flex flex-col justify-between rounded-md animated fadeInLeft delay-1s">
        <div class="pt-3 text-start px-3">
          {{ $t('BillActionsText', language, strings) }}
        </div>
        <div class="p-3 flex flex-wrap gap-3 justify-center">
          <z-button
            class="flex-1"
            @click="onClickGetBill(1)"
            size="small"
            style="min-width: 80px"
            outlined>
            {{
              parsedDate
                .clone()
                .subtract(1, 'month')
                .startOf('month')
                .locale(language)
                .format('MMMM')
            }}
          </z-button>
          <z-button
            class="flex-1"
            @click="onClickGetBill(2)"
            size="small"
            style="min-width: 80px"
            outlined>
            {{
              parsedDate
                .clone()
                .subtract(2, 'month')
                .startOf('month')
                .locale(language)
                .format('MMMM')
            }}
          </z-button>
          <z-button
            class="flex-1"
            @click="() => cardState++"
            size="small"
            style="min-width: 80px"
            outlined>
            {{ $t('ChooseAnotherDate', language, strings) }}
          </z-button>
        </div>
      </div>

      <div
        v-if="cardState >= 1"
        class="p-3 rounded-md shadow-md text-cell bg-white text-start animated fadeInLeft delay-500">
        {{ $t('AnotherDateText1', language, strings) }}<br />{{
          $t('AnotherDateText2', language, strings)
        }}
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import { ZButton } from '@/zain-component-library/src'
import { mapActions } from 'vuex'
import mixins from '@/mixins'

export default {
  components: { ZButton },
  props: ['billUrl', 'billDate', 'isDateProvided', 'isNoDataFound', 'language'],
  name: 'BillDetails',
  mixins: [mixins],
  data() {
    return {
      cardState: 0,
      moment: moment,
      parsedDate: moment(this.billDate, 'YYYY-MM-DD'),
      strings: {
        NoDataFound: {
          en: 'No data found for the selected period',
          ar: 'لا توجد بيانات عن الفترة المحددة'
        },
        DownloadBill: {
          en: 'Download Bill',
          ar: 'تحميل الفاتورة'
        },
        BillActionsText: {
          en: 'You can get your previous bills below or specify a month',
          ar: 'يمكنك الحصول على فواتيرك السابقة أدناه أو تحديد شهر'
        },
        ChooseAnotherDate: {
          en: 'Choose Another Date',
          ar: 'اختر تاريخ آخر'
        },
        AnotherDateText1: {
          en: 'Specify a previous month date in the format of DD/MM/YYYY.',
          ar: 'حدد تاريخ الشهر السابق بتنسيق DD / MM / YYYY.'
        },
        AnotherDateText2: {
          en: "For example, 'bill details 01/01/2022'",
          ar: 'على سبيل المثال ، "تفاصيل الفاتورة 01/01/2022"'
        }
      }
    }
  },
  methods: {
    ...mapActions('chatModule', ['sendHiddenMessage']),
    onClickGetBill(subtractMonths = 0) {
      let date = this.parsedDate.clone().subtract(subtractMonths, 'month')
      // If clicked on latest bill
      if (subtractMonths === 0) {
        window.open(this.billUrl)
      } else {
        this.sendHiddenMessage({
          text: `${this.language === 'en' ? 'bill details' : 'تفاصيل الفاتورة'} ${date.format(
            'DD/MM/YYYY'
          )}`,
          locale: this.language
        })
      }
    }
  }
}
</script>
