<!--suppress NonAsciiCharacters -->
<template>
  <div class="text-center">
    <h6 class="animated fadeInDown" style="display: block; width: 100%">{{ title }}</h6>
    <p>{{ extraText }}</p>
    <div class="list-item">
      <div :dir="language === 'ar' ? 'rtl' : 'ltr'">
        <div>
          <div
            :key="idx"
            class="swiper-slide animated help-item text-center"
            :ref="'hcell' + idx"
            v-on:click="onCardAction(btn)"
            v-for="(btn, idx) in allButtons">
            <i
              v-if="btn.icon"
              class="fa"
              aria-hidden="true"
              :class="[btn.icon, 'purple-outline-btn-i']"></i>
            <p :class="{ larger: language === 'ar' }">{{ btn.title | toCaps }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
// import Swiper from 'swiper/dist/js/swiper.esm.bundle'

export default {
  name: 'HelpCell',
  props: ['buttons', 'title', 'extraText', 'attachments', 'language', 'helpInfo'],
  data() {
    return {}
  },
  methods: {
    ...mapActions(['sendChatMessage']),
    onCardAction(btn) {
      if (btn.type === 'imBack') this.sendChatMessage(btn.value)
      if (btn.type === 'openUrl') window.open(btn.value)
    },
    animateOpacityStaggered() {
      let vm = this
      for (let i = 0; i < this.allButtons.length; i++) {
        setTimeout(() => {
          vm.$refs['hcell' + i][0].setAttribute(
            'class',
            vm.$refs.hcell0[0].getAttribute('class') + ' ' + 'fadeInDown'
          )
        }, 275 * i)
      }
    }
  },
  // directives: {
  //   carousel: {
  //     inserted: function (el, binding) {
  //       if (binding.value.count === 0) return
  //       // eslint-disable-next-line no-unused-vars
  //       const swiper = new Swiper(el, {
  //         spaceBetween: 10,
  //         freeMode: false,
  //         mousewheel: true,
  //         grabCursor: true,
  //         observer: true,
  //         pagination: {
  //           el: binding.value.count > 3 ? el.childNodes[2] : null,
  //           dynamicBullets: true
  //         },
  //         slidesPerView: 'auto'
  //       })
  //     }
  //   }
  // },
  computed: {
    allButtons() {
      let allButtons = []
      for (let obj in this.helpInfo) {
        if (this.helpInfo.hasOwnProperty(obj)) allButtons.push(...this.helpInfo[obj].actions)
      }
      allButtons = allButtons.sort((a, b) => a.sequence - b.sequence)
      return allButtons
    }
  },
  filters: {
    toCaps: function (value) {
      if (!value) return ''
      return value.toUpperCase()
    }
  },
  mounted() {
    this.animateOpacityStaggered()
  }
}
</script>

<style scoped>
.help-item {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  /*height: 105px;*/
  cursor: pointer;
  margin: 0 auto 10px;
  padding: 15px;
  opacity: 0;
  max-width: 550px;
}

.help-item:hover {
  background: #d02486;
}

.help-btn {
  display: block;
  position: relative;
  margin-top: 0.6rem;
}

p {
  font-size: 0.8em;
  /*color: #d02486;*/
  padding: 0 5px;
  line-height: 1;
  /*display: table-cell;*/
  vertical-align: middle;
}

.purple-outline-btn-i {
  position: relative;
  left: auto;
  top: auto;
  font-size: 1.6em;
  display: block;
  /*color: #d02486;*/
  padding: 18px 0;
}

.purple-outline-btn-ar-i {
  position: absolute;
  right: 25px;
  top: 10px;
  font-size: 1.7em;
  -webkit-transform: translate(50%, 0);
  -ms-transform: translate(50%, 0);
  transform: translate(50%, 0);
}
.text-right-imp {
  text-align: right !important;
}
.purple-outline-btn {
  min-height: auto;
  line-height: 2.2;
  padding: 0.35em 3.2em;
  text-align: left;
}
.larger {
  font-size: 1em;
}
</style>
