<template>
  <div class="flex items-center">
    <!--suppress HtmlFormInputWithoutLabel -->
    <label :for="getUUID()"
           class="flex items-center cursor-pointer border border-gray-300 p-2 rounded-md w-full hover:border-black ring-opacity-40"
           :class="{'border-primary': computedValue === nativeValue, 'ring-offset-2 ring-2 ring-primary': isFocused }">
      <input v-model="computedValue" :id="getUUID()" type="radio" :name="name" class="opacity-0 w-0" :value="nativeValue"
             @focus="isFocused = true" @blur="isFocused = false"/>
      <slot/>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ZRadioButton',
  props: {
    value: [String, Number, Boolean, Function, Object, Array],
    nativeValue: [String, Number, Boolean, Function, Object, Array],
    name: {
      type: String,
      default: 'radio'
    },
    label: {
      type: String
    }
  },
  data() {
    return {
      uuid: null,
      newValue: this.value,
      isFocused: false
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value(value) {
      this.newValue = value
    }
  },
  methods: {
    getUUID() {
      if (!this.uuid) {
        this.uuid = 'radio-' + this.$uuid()
      }
      return this.uuid
    },
  }
}
</script>

<style scoped>
input[type="radio"]:checked + label {
  border-color: #D12B8A;
}

</style>
