<template>
  <div
      class="b-slider mb-4"
      @click="onSliderClick"
      :class="[size, type, rootClasses, {'mt-16': ticks || $slots.default}, {'mt-2': !ticks && !$slots.default} ]">
    <div
        class="b-slider-track"
        ref="slider">
      <div
          class="b-slider-fill bg-primary" style="transition: width 1s"
          :style="barStyle"/>
      <template v-if="ticks">
        <z-slider-tick
            v-for="(val, key) in tickValues"
            :key="key"
            :value="val">{{ val }}
        </z-slider-tick>
      </template>
      <slot/>
      <z-slider-thumb
          v-model="value1"
          :tooltip="tooltip"
          :custom-formatter="customFormatter"
          :indicator="indicator"
          :format="format"
          ref="button1"
          role="slider"
          :aria-valuenow="value1"
          :aria-valuemin="min"
          :aria-valuemax="max"
          aria-orientation="horizontal"
          :aria-label="Array.isArray(ariaLabel) ? ariaLabel[0] : ariaLabel"
          :aria-disabled="disabled"
          @dragstart="onDragStart"
          @dragend="onDragEnd"/>

    </div>
  </div>
</template>

<script>
import ZSliderThumb from './ZSliderThumb'
import ZSliderTick from './ZSliderTick'


/**
 * Asserts a value is between min and max
 * @param val
 * @param min
 * @param max
 * @returns {number}
 */
function bound(val, min, max) {
  return Math.max(min, Math.min(max, val))
}

export default {
  name: 'ZSlider',
  components: {
    ZSliderThumb,
    ZSliderTick,
    [ZSliderThumb.name]: ZSliderThumb,
    [ZSliderTick.name]: ZSliderTick
  },
  props: {
    value: {
      type: [Number, Array],
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 5
    },
    step: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      default: 'is-primary'
    },
    size: String,
    ticks: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: Boolean,
      default: true
    },
    tooltipType: String,
    rounded: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: false
    },
    customFormatter: Function,
    ariaLabel: [String, Array],
    biggerSliderFocus: {
      type: Boolean,
      default: false
    },
    indicator: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: 'raw',
      validator: (value) => {
        return [
          'raw',
          'percent'
        ].indexOf(value) >= 0
      }
    }
  },
  data() {
    return {
      value1: null,
      value2: null,
      dragging: false,
      isRange: false,
      isSlider: true // Used by Thumb and Tick
    }
  },
  computed: {
    tickValues() {
      if (!this.ticks || this.min > this.max || this.step === 0) return []
      const result = []
      for (let i = this.min; i < this.max + 1; i = i + this.step) {
        result.push(i)
      }
      return result
    },
    minValue() {
      return Math.min(this.value1, this.value2)
    },
    maxValue() {
      return Math.max(this.value1, this.value2)
    },
    barSize() {
      return this.isRange
          ? `${ 100 * (this.maxValue - this.minValue) / (this.max - this.min) }%`
          : `${ 100 * (this.value1 - this.min) / (this.max - this.min) }%`
    },
    barStart() {
      return this.isRange
          ? `${ 100 * (this.minValue - this.min) / (this.max - this.min) }%`
          : '0%'
    },
    precision() {
      const precisions = [this.min, this.max, this.step].map((item) => {
        let decimal = ('' + item).split('.')[1]
        return decimal ? decimal.length : 0
      })
      return Math.max(...precisions)
    },
    barStyle() {
      return {
        width: this.barSize,
        left: this.barStart
      }
    },
    rootClasses() {
      return {
        'is-rounded': this.rounded,
        'is-dragging': this.dragging,
        'is-disabled': this.disabled,
        'slider-focus': this.biggerSliderFocus
      }
    }
  },
  watch: {
    /**
     * When v-model is changed set the new active step.
     */
    value(value) {
      this.setValues(value)
    },
    value1() {
      this.onInternalValueUpdate()
    },
    value2() {
      this.onInternalValueUpdate()
    },
    min() {
      this.setValues(this.value)
    },
    max() {
      this.setValues(this.value)
    }
  },
  methods: {
    setValues(newValue) {
      if (this.min > this.max) {
        return
      }
      if (Array.isArray(newValue)) {
        this.isRange = true
        const smallValue = typeof newValue[0] !== 'number' || isNaN(newValue[0])
            ? this.min
            : bound(newValue[0], this.min, this.max)
        const largeValue = typeof newValue[1] !== 'number' || isNaN(newValue[1])
            ? this.max
            : bound(newValue[1], this.min, this.max)
        this.value1 = this.isThumbReversed ? largeValue : smallValue
        this.value2 = this.isThumbReversed ? smallValue : largeValue
      } else {
        this.isRange = false
        this.value1 = isNaN(newValue)
            ? this.min
            : bound(newValue, this.min, this.max)
        this.value2 = null
      }
    },
    onInternalValueUpdate() {
      if (this.isRange) {
        this.isThumbReversed = this.value1 > this.value2
      }
      if (!this.lazy || !this.dragging) {
        this.emitValue('input')
      }
      if (this.dragging) {
        this.emitValue('dragging')
      }
    },
    sliderSize() {
      return this.$refs.slider.getBoundingClientRect().width
    },
    onSliderClick(event) {
      if (this.disabled || this.isTrackClickDisabled) return
      const sliderOffsetLeft = this.$refs.slider.getBoundingClientRect().left
      const percent = (event.clientX - sliderOffsetLeft) / this.sliderSize() * 100
      const targetValue = this.min + percent * (this.max - this.min) / 100
      const diffFirst = Math.abs(targetValue - this.value1)
      if (!this.isRange) {
        if (diffFirst < this.step / 2) return
        this.$refs.button1.setPosition(percent)
      } else {
        const diffSecond = Math.abs(targetValue - this.value2)
        if (diffFirst <= diffSecond) {
          if (diffFirst < this.step / 2) return
          this.$refs['button1'].setPosition(percent)
        } else {
          if (diffSecond < this.step / 2) return
          this.$refs['button2'].setPosition(percent)
        }
      }
      this.emitValue('change')
    },
    onDragStart() {
      this.dragging = true
      this.$emit('dragstart')
    },
    onDragEnd() {
      this.isTrackClickDisabled = true
      setTimeout(() => {
        // avoid triggering onSliderClick after dragend
        this.isTrackClickDisabled = false
      }, 0)
      this.dragging = false
      this.$emit('dragend')
      if (this.lazy) {
        this.emitValue('input')
      }
    },
    emitValue(type) {
      this.$emit(type, this.isRange
          ? [this.minValue, this.maxValue]
          : this.value1)
    }
  },
  created() {
    this.isThumbReversed = false
    this.isTrackClickDisabled = false
    this.setValues(this.value)
  }
}
</script>

<style>

.b-slider {
  background: transparent;
  width: 100%;
}

.b-slider .b-slider-track {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: #dbdbdb;
  border-radius: 4px;
}

.b-slider .b-slider-fill {
  position: absolute;
  height: 100%;
  box-shadow: 0 0 0 #7a7a7a;
  /*background: #dbdbdb;*/
  border-radius: 4px;
  border: 0 solid #7a7a7a;
  top: 50%;
  transform: translateY(-50%);
}

.b-slider.slider-focus {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: -20px;
  margin-bottom: -20px;
  cursor: pointer;
}

.b-slider.is-disabled .b-slider-track {
  cursor: not-allowed;
  opacity: 0.5;
}


.b-slider .b-slider-track {
  height: 0.35rem;
}


/*.b-slider.is-small .b-slider-track {*/
/*  height: 0.375rem;*/
/*}*/
/*.b-slider.is-small .b-slider-thumb {*/
/*  height: 0.75rem;*/
/*  width: 0.75rem;*/
/*}*/
/*.b-slider.is-small .b-slider-tick {*/
/*  height: 0.1875rem;*/
/*}*/
/*.b-slider.is-small .b-slider-tick-label {*/
/*  font-size: 0.75rem;*/
/*  position: absolute;*/
/*  top: calc(0.375rem / 2 + 2px);*/
/*  left: 50%;*/
/*  transform: translateX(-50%);*/
/*}*/
/*.b-slider.is-medium .b-slider-track {*/
/*  height: 0.625rem;*/
/*}*/
/*.b-slider.is-medium .b-slider-thumb {*/
/*  height: 1.25rem;*/
/*  width: 1.25rem;*/
/*}*/
/*.b-slider.is-medium .b-slider-tick {*/
/*  height: 0.3125rem;*/
/*}*/
/*.b-slider.is-medium .b-slider-tick-label {*/
/*  font-size: 0.75rem;*/
/*  position: absolute;*/
/*  top: calc(0.625rem / 2 + 2px);*/
/*  left: 50%;*/
/*  transform: translateX(-50%);*/
/*}*/
/*.b-slider.is-large .b-slider-track {*/
/*  height: 0.75rem;*/
/*}*/
/*.b-slider.is-large .b-slider-thumb {*/
/*  height: 1.5rem;*/
/*  width: 1.5rem;*/
/*}*/
/*.b-slider.is-large .b-slider-tick {*/
/*  height: 0.375rem;*/
/*}*/
/*.b-slider.is-large .b-slider-tick-label {*/
/*  font-size: 0.75rem;*/
/*  position: absolute;*/
/*  top: calc(0.75rem / 2 + 2px);*/
/*  left: 50%;*/
/*  transform: translateX(-50%);*/
/*}*/

</style>
