<template>
  <div>
    <div class="text-center animated bounceIn" v-if="gameOver">
      {{ this.winner === 'D' ? 'Draw!' : this.winner + ' Wins!' }} <a @click="reset">Play Again</a>
    </div>
    <div style="margin: 0 auto" class="grid-x text-center width-50">
      <div
        class="medium-4 small-4 cell"
        v-for="(square, index) in tttArray"
        v-bind:key="index"
        @click="clickBox(index)"
        :style="
          (index < 6 ? 'border-bottom: 5px solid white;' : '') +
          (index === 1 || index === 4 || index === 7
            ? 'border-right: 5px solid white; border-left: 5px solid white'
            : '')
        ">
        {{ square === 'X' || square === 'O' ? square : '&nbsp;' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TicTacToe',
  data() {
    return {
      tttArray: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      patternChecks: [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6]
      ],
      gameOver: false,
      winner: '',
      fc: 0,
      huPlayer: 'X',
      aiPlayer: 'O'
    }
  },
  methods: {
    clickBox(index) {
      if (this.tttArray[index] !== 'X' && this.tttArray[index] !== 'O' && !this.gameOver) {
        // set human play
        this.$set(this.tttArray, index, 'X')

        // if game is not over
        if (!this.checkIfSomeoneWon()) {
          // set ai play
          this.$set(this.tttArray, this.miniMax(this.tttArray, 'O').index, 'O')
          this.checkIfSomeoneWon()
        }
      }
    },
    checkIfSomeoneWon() {
      let lineMadeOrDraw = false
      if (this.tttArray.filter((x) => x !== 'X' && x !== 'O').length === 0) {
        lineMadeOrDraw = true
        this.gameOver = true
        this.winner = 'D'
      } else {
        for (let i = 0; i < this.patternChecks.length; i++) {
          if (
            this.tttArray[this.patternChecks[i][0]] === this.tttArray[this.patternChecks[i][1]] &&
            this.tttArray[this.patternChecks[i][0]] === this.tttArray[this.patternChecks[i][2]]
          ) {
            lineMadeOrDraw = true
            this.winner = this.tttArray[this.patternChecks[i][0]]
            this.gameOver = true
            break
          }
        }
      }
      return lineMadeOrDraw
    },
    miniMax(newBoard, player) {
      // add one to function calls
      this.fc++

      // available spots
      let availSpots = this.tttArray.filter((x) => x !== 'X' && x !== 'O')

      // checks for the terminal states such as win, lose, and tie and returning a value accordingly
      if (this.winning(newBoard, this.huPlayer)) {
        return { score: -10 }
      } else if (this.winning(newBoard, this.aiPlayer)) {
        return { score: 10 }
      } else if (availSpots.length === 0) {
        return { score: 0 }
      }

      // an array to collect all the objects
      let moves = []

      // loop through available spots
      for (let i = 0; i < availSpots.length; i++) {
        // create an object for each and store the index of that spot that was stored as a number in the object's index key
        let move = {}
        move.index = newBoard[availSpots[i]]

        // set the empty spot to the current player
        newBoard[availSpots[i]] = player

        // collect the score resulted from calling miniMax on the opponent of the current player
        move.score = this.miniMax(
          newBoard,
          player === this.aiPlayer ? this.huPlayer : this.aiPlayer
        ).score

        // reset the spot to empty
        newBoard[availSpots[i]] = move.index

        // push the object to the array
        moves.push(move)
      }

      // if it is the computer's turn loop over the moves and choose the move with the highest score
      let bestMove
      if (player === this.aiPlayer) {
        let bestScore = -10000
        for (let i = 0; i < moves.length; i++) {
          if (moves[i].score > bestScore) {
            bestScore = moves[i].score
            bestMove = i
          }
        }
      } else {
        // else loop over the moves and choose the move with the lowest score
        let bestScore = 10000
        for (let i = 0; i < moves.length; i++) {
          if (moves[i].score < bestScore) {
            bestScore = moves[i].score
            bestMove = i
          }
        }
      }

      // return the chosen move (object) from the array to the higher depth
      return moves[bestMove]
    },
    winning(board, player) {
      return (
        (board[0] === player && board[1] === player && board[2] === player) ||
        (board[3] === player && board[4] === player && board[5] === player) ||
        (board[6] === player && board[7] === player && board[8] === player) ||
        (board[0] === player && board[3] === player && board[6] === player) ||
        (board[1] === player && board[4] === player && board[7] === player) ||
        (board[2] === player && board[5] === player && board[8] === player) ||
        (board[0] === player && board[4] === player && board[8] === player) ||
        (board[2] === player && board[4] === player && board[6] === player)
      )
    },
    reset() {
      this.winner = ''
      this.gameOver = false
      this.tttArray = [0, 1, 2, 3, 4, 5, 6, 7, 8]
    }
  }
}
</script>
<style>
.medium-4.small-4.cell {
  background-color: skyblue;
  font-weight: bold;
  color: white;
  font-size: 2.4em;
}
</style>
