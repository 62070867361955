<template>
  <label class="inline-flex items-center w-full" :class="{ 'cursor-not-allowed': $attrs.disabled === '' || $attrs.disabled, 'cursor-pointer': !$attrs.disabled}">
    <input v-bind="$attrs" v-model="computedValue" ref="input" :class="{ 'bg-gray-200 cursor-not-allowed': $attrs.disabled === '' || $attrs.disabled, 'bg-pink-100 cursor-pointer': !$attrs.disabled}"
           class="form-radio text-primary border-transparent focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-400"
           type="radio" :name="name" :value="nativeValue">
    <span v-if="label" class="ms-3" :class="labelClass">{{ label }}</span>
    <slot v-else/>
  </label>
  <!--  <div class="flex items-center mr-4">-->
  <!--    &lt;!&ndash;suppress HtmlFormInputWithoutLabel &ndash;&gt;-->
  <!--    <input v-model="computedValue" ref="input" :id="getUUID()" type="radio" :name="name" class="hidden" :value="nativeValue"/>-->
  <!--    <label :for="getUUID()" class="flex items-center cursor-pointer text-base">-->
  <!--      <span-->
  <!--          class="w-8 h-8 inline-block mr-2 rounded-full border border-gray-200 dark:border-gray-500 flex-no-shrink"></span>-->
  <!--      {{ label }}-->
  <!--    </label>-->
  <!--  </div>-->
</template>

<script>
export default {
  name: 'ZRadio',
  props: {
    value: [String, Number, Boolean, Function, Object, Array],
    nativeValue: [String, Number, Boolean, Function, Object, Array],
    name: {
      type: String,
      default: 'radio'
    },
    label: {
      type: String
    },
    labelClass: [String, Object]
  },
  data() {
    return {
      newValue: this.value
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value(value) {
      this.newValue = value
    }
  },
  methods: {},
  mounted() {
  }
}
</script>
