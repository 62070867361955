let mixins = {
  methods: {
    // translator
    $t(term, lang, strings) {
      return strings[term][lang]
    }
  }
}

export default mixins
