<template>
  <div>
    <div
      class="grid-x"
      style="margin: 0 auto; max-width: 580px"
      :style="language === 'en' ? '' : 'text-align: right'">
      <h6 style="font-size: 0.9em" class="rate-header large-12 cell">
        <span style="height: 30px; padding-top: 5px" class="float-left"
          ><i style="color: #ffed00" class="fa fa-calendar" aria-hidden="true"></i>
          {{ prayerTimes.date.readable }}
        </span>
        <span class="float-right"
          >{{ language === 'en' ? 'Kuwait City' : 'مدينة الكويت' }}
          <img style="height: 30px" src="../../assets/kuwait-flag.png"
        /></span>
      </h6>

      <div class="rateContainer small-12 cell">
        <div class="display-inline-block">
          <p class="bold">{{ localisationKeys.Fajr }}</p>
        </div>
        <div class="display-inline-block" :class="language === 'en' ? 'float-right' : 'float-left'">
          <p class="bold">{{ prayerTimes.timings.fajr.substring(5, 0) }}</p>
        </div>
      </div>

      <div class="rateContainer small-12 cell">
        <div class="display-inline-block">
          <p class="bold">{{ localisationKeys.Dhuhr }}</p>
        </div>
        <div class="display-inline-block" :class="language === 'en' ? 'float-right' : 'float-left'">
          <p class="bold">{{ prayerTimes.timings.dhuhr.substring(5, 0) }}</p>
        </div>
      </div>

      <div class="rateContainer small-12 cell">
        <div class="display-inline-block">
          <p class="bold">{{ localisationKeys.Asr }}</p>
        </div>
        <div class="display-inline-block" :class="language === 'en' ? 'float-right' : 'float-left'">
          <p class="bold">{{ prayerTimes.timings.asr.substring(5, 0) }}</p>
        </div>
      </div>

      <div class="rateContainer small-12 cell">
        <div class="display-inline-block">
          <p class="bold">{{ localisationKeys.Maghrib }}</p>
        </div>
        <div class="display-inline-block" :class="language === 'en' ? 'float-right' : 'float-left'">
          <p class="bold">{{ prayerTimes.timings.maghrib.substring(5, 0) }}</p>
        </div>
      </div>

      <div class="rateContainer small-12 cell">
        <div class="display-inline-block">
          <p class="bold">{{ localisationKeys.Isha }}</p>
        </div>
        <div class="display-inline-block" :class="language === 'en' ? 'float-right' : 'float-left'">
          <p class="bold">{{ prayerTimes.timings.isha.substring(5, 0) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//    <p>Your total balance is {{ unbilled + pastDue }} KD - Unbilled: {{ unbilled }} - Past Due: {{ pastDue }} </p>
export default {
  name: 'PrayerTimes',
  props: ['prayerTimes', 'localisationKeys', 'language']
}
</script>

<style scoped>
.rateContainer {
  background: #f3f3f3;
  padding: 5px;
  border: 1px rgb(255, 255, 255) solid;
  font-size: 0.8rem;
}
.rateContainer p {
  margin: 0;
}
.rate-header {
  background-color: rgb(182, 97, 182);
  padding: 5px;
  color: white;
  margin: 0;
  border: 1px rgb(255, 255, 255) solid;
}
small,
p,
a,
h5,
h6 {
  /* font-family: ZainArabic; */
  font-size: 1.1em;
}
</style>
