import Swiper from 'swiper/swiper-bundle.min'

export default {
  directives: {
    carousel: {
      inserted: function (el) {
        // eslint-disable-next-line no-unused-vars
        new Swiper(el, {
          pagination: {
            el: el.childNodes[1],
            clickable: true,
            dynamicBullets: true
          },
          spaceBetween: 10,
          freeMode: false,
          mousewheel: true,
          grabCursor: true,
          observer: true,
          breakpointsBase: 'container',
          breakpoints: {
            320: {
              slidesPerView: 2
            },
            600: {
              slidesPerView: 3
            },
            2000: {
              slidesPerView: 3
            }
          }
        })
      },
      componentUpdated: function (el) {
        el.swiper.slideTo()
      }
    }
  }
}
