<template>
  <div>
    <slot :type="type" />
    <div class="text-sm pt-1"
         :class="getMessageClasses()">
      <span v-if="this.getType === 'danger' && message" class="icon-Shortcut---close text-danger text-xs"></span>
      <span v-else-if="this.getType === 'success'" class="icon-check-mark-button text-green text-xs"></span>

      {{ message }}
    </div>
  </div>
</template>

<script>
import formMixin from '../utils/mixins/formMixin'

export default {
  name: "ZField",
  mixins: [formMixin],
  props: {
    message: {
      type: String
    },
  },
  methods: {
    getMessageClasses() {
      return [
        {
          'text-danger': this.getType === 'danger',
          'text-green': this.getType === 'success',
        }
      ]
    }
  },
  data() {
    return {
      isField: true
    }
  }
}
</script>

<style scoped>

</style>
