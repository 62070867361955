<template>
  <div
      class="b-slider-tick transition-colors duration-1000"
      :class="{'bg-primary': active, 'bg-purple-300': !active, 'tick-active': activeExact}"
      :style="[getTickStyle(position)]">
        <span v-if="$slots.default" :class="{ 'bg-white border border-info rounded-lg text-primary dark:text-primary': activeExact}"
              class="b-slider-tick-label py-1 px-6 font-bold text-purple-900 dark:text-purple-200 text-sm">
            <slot/>
        </span>
  </div>
</template>

<script>
export default {
  name: 'ZSliderTick',
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    active() {
      return this.$parent.value1 >= this.value
    },
    activeExact() {
      return this.$parent.value1 === this.value
    },
    position() {
      const pos = (this.value - this.$parent.min) /
          (this.$parent.max - this.$parent.min) * 100
      return (pos >= 0 && pos <= 100) ? pos : 0
    }
  },
  methods: {
    getTickStyle(position) {
      return { 'left': position + '%' }
    }
  },
  created() {
    if (!this.$parent.$data.isSlider) {
      this.$destroy()
      throw new Error('You should wrap bSliderTick on a bSlider')
    }
  }
}
</script>

<style>

.b-slider-tick-label {
  position: absolute;
  top: calc(-6rem / 2 + 2px);
  left: 50%;
  transform: translateX(-50%);
}


.b-slider-tick {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  /*background: #b5b5b5;*/
  user-select: none;
}

.b-slider-tick {
  width: 4px;
  height: 1rem;
}

.b-slider-tick:not(:nth-child(2)):not(:nth-last-child(2)) {
  width: 1rem;
  border-radius: 50%;
}

.b-slider-tick.is-tick-hidden {
  background: transparent;
}

.tick-active ::after {
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  border-style: solid;
  /*border-color: #0aafb7;*/
  border-width: 0 1px 1px 0;
  transform: translateX(-50%) rotate(45deg);
  transition: border-width 150ms ease-in-out;
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  background: #fff;
}
</style>
