<template>
  <div>
    <transition name="zoom" tag="div" mode="out-in">
      <div :key="1" v-if="cardState === cardStates.ServiceView">
        <div class="width-100 text-center" v-if="serviceFilter">
          <a class="closable-filter" @click="clearFilter()"
            >{{ serviceFilter.charAt(0).toUpperCase() + serviceFilter.slice(1) }}
            <i class="fa fa-times-circle"></i
          ></a>
        </div>
        <div
          class="swiper-container"
          v-carousel="{ elementsLength: computedZainServices.length }"
          style="height: 265px"
          :dir="language === 'ar' ? 'rtl' : 'ltr'">
          <div v-if="computedZainServices.length > 0" class="swiper-wrapper">
            <div
              v-for="(service, index) in computedZainServices"
              v-bind:key="index"
              class="swiper-slide item text-center">
              <h6 class="service-header">
                {{ language === 'ar' ? service.titleAr : service.titleEn }}
              </h6>
              <div class="small-circle">
                <i
                  class="fa fa-2x margin-bottom-1"
                  :class="'fa-' + service.icon"
                  aria-hidden="true"></i>
              </div>
              <p :style="setArabicRTL()" class="service-price green bold">
                <span v-if="service.price > 0"
                  ><span v-if="service.price > 0.25">KD</span> {{ service.price.toFixed(3) }}
                  {{ ' ' }}</span
                >{{ language === 'ar' ? service.priceDescriptionAr : service.priceDescriptionEn }}
              </p>
              <a
                @click="selectService(service)"
                v-if="service.canAdd"
                class="add-service-button button zain-green-bg"
                >+</a
              >
              <a
                v-else-if="service.isInstalled"
                class="add-service-button button zain-green-bg active"
                style="font-size: 1.1rem"
                >{{ language === 'en' ? 'Active' : 'مفعل' }}</a
              >
            </div>
          </div>
          <div v-else class="swiper-slide item text-center">No Services Found</div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div
        :key="2"
        class="services-container"
        v-else-if="cardState === cardStates.ConfirmAdd && selectedService">
        <a
          style="color: black; position: absolute; left: 10px; top: 10px; font-size: 21px"
          @click="cancelAddService"
          ><i class="fa fa-chevron-left"></i
        ></a>
        <h5 :style="setArabicRTL()">
          {{ language === 'en' ? 'Confirm Adding' : 'تاكيد الاضافة' }}
        </h5>
        <strong>{{ language === 'ar' ? selectedService.titleAr : selectedService.titleEn }}</strong>
        <br />
        <div class="small-circle">
          <i
            class="fa fa-2x margin-bottom-1"
            :class="'fa-' + selectedService.icon"
            aria-hidden="true"></i>
        </div>
        <div style="background-color: #f3f3f3; text-align: center; padding: 10px 20px">
          <span
            class="animated fadeInLeft"
            style="color: #7bc440; font-family: Helvetica, Arial, sans-serif; font-weight: bold">
            <span v-if="selectedService.price > 0"
              ><span v-if="selectedService.price > 0.25">KD</span>
              <span style="font-size: 2em">{{ selectedService.price.toFixed(3) }} </span> {{ ' ' }}
            </span>
            {{
              language === 'ar'
                ? selectedService.priceDescriptionAr
                : selectedService.priceDescriptionEn
            }}
          </span>
        </div>
        <br />
        <div v-if="isEpinEnabled" style="padding: 0 30px">
          <label>
            E-PIN:
            <input
              placeholder="Enter your E-Pin"
              required
              v-model="ePin"
              type="text"
              style="width: 200px; margin: 0 auto 20px"
              maxlength="4" />
          </label>
          <div style="padding-bottom: 10px; color: red" v-if="epinError">{{ epinError }}</div>
        </div>
        <a v-if="isEpinEnabled" @click="resetEpin" class="purple-outline-btn" style="width: 47%">
          FORGOT E-PIN?
        </a>
        <a
          @click="confirmAddService"
          class="purple-outline-btn inverted"
          :style="isEpinEnabled ? 'width: 47%' : 'width: 94%'">
          {{ language === 'en' ? 'CONFIRM' : 'تأكيد' }}
        </a>
      </div>
      <div
        :key="3"
        class="services-container"
        v-else-if="cardState === cardStates.AddingInProgress">
        <h5 :style="language === 'ar' ? 'direction: rtl' : ''">
          {{ language === 'en' ? 'Adding... ' : 'جاري الاضافة...' }}
          <strong>{{
            language === 'ar' ? selectedService.titleAr : selectedService.titleEn
          }}</strong>
        </h5>
        <br />
        <div class="small-circle">
          <i
            class="fa fa-2x margin-bottom-1"
            :class="'fa-' + selectedService.icon"
            aria-hidden="true"></i>
        </div>
        <p class="service-price green bold">
          <span v-if="selectedService.price > 0"
            ><span v-if="selectedService.price > 0.25">KD</span>
            {{ selectedService.price.toFixed(3) }} {{ ' ' }}</span
          >
          {{
            language === 'ar'
              ? selectedService.priceDescriptionAr
              : selectedService.priceDescriptionEn
          }}
        </p>
        <br />
      </div>
      <div :key="4" class="services-container" v-else-if="cardState === cardStates.ResultsView">
        <h5>
          <strong>{{
            language === 'ar' ? selectedService.titleAr : selectedService.titleEn
          }}</strong>
        </h5>
        <br />
        <div class="small-circle">
          <i
            class="fa fa-2x margin-bottom-1"
            style="top: -4px; position: relative"
            :class="resultIsSuccess ? 'fa-check' : 'fa-times'"
            aria-hidden="true"></i>
        </div>
        <br />
        {{ resultString }}
        <br /><br />
        <a
          v-if="!resultIsSuccess"
          class="purple-outline-btn"
          @click="cardState = cardStates.ConfirmAdd"
          >Back</a
        >
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Swiper from 'swiper/swiper-bundle'
import EventBus from '../../eventBus'

export default {
  name: 'AddServicesCell',
  props: {
    isEpinEnabled: {
      type: Boolean,
      default: true
    },
    zainServices: {
      type: Array
    },
    entities: {
      type: Array
    },
    language: {
      type: String,
      default: 'en'
    },
    id: {
      type: String
    }
  }, // ['isEpinEnabled', 'zainServices', 'entities', 'language', 'id'],
  data() {
    return {
      billingType: '',
      added: false,
      cardStates: {
        ServiceView: 0,
        ConfirmAdd: 1,
        AddingInProgress: 2,
        ResultsView: 3
      },
      cardState: 0,
      selectedService: '',
      serviceFilter: '',
      resultIsSuccess: false,
      resultString: '',
      ePin: '',
      epinError: ''
    }
  },
  computed: {
    ...mapState(['user']),
    computedZainServices() {
      let servs = this.zainServices
      if (this.serviceFilter) {
        servs = servs.filter((x) => x.group.toLowerCase() === this.serviceFilter)
      }
      return servs
    }
  },
  methods: {
    ...mapActions(['sendHiddenMessage', 'sendChatMessage']),
    selectService(service) {
      this.selectedService = service
      this.cardState = this.cardStates.ConfirmAdd
    },
    resetEpin() {
      this.sendChatMessage('reset-pin')
    },
    confirmAddService() {
      if (this.isEpinEnabled && !this.ePin) {
        this.epinError = '*E-PIN is Required'
        return
      }
      let servStr = `${this.selectedService.serviceCode}%${this.id}`

      if (this.isEpinEnabled) servStr += `%${this.ePin}`

      this.sendHiddenMessage({
        text: `<context serviceCode=${servStr}>`,
        locale: this.language,
        fromMessageId: this.id
      })
      this.cardState = this.cardStates.AddingInProgress
      this.$ga.event('AddService', this.selectedService.serviceCode)
    },
    cancelAddService() {
      this.selectedService = ''
      this.cardState = this.cardStates.ServiceView
    },
    getServiceLocalization(ser, lang) {
      return this.services.find(
        (x) => x.serviceCode.indexOf(ser.serviceCode) !== -1 && x.language.indexOf(lang) !== -1
      )
    },
    setArabicRTL() {
      return this.language === 'ar' ? 'direction:rtl' : ''
    },
    clearFilter() {
      this.serviceFilter = ''
      this.$forceUpdate()
    }
  },
  directives: {
    carousel: {
      inserted: function (el, binding) {
        // console.log('inserted', binding.value)
        if (binding.value.elementsLength > 1) {
          // eslint-disable-next-line no-unused-vars
          const swiper = new Swiper(el, {
            pagination: {
              el: el.childNodes[2],
              clickable: true,
              dynamicBullets: true
            },
            spaceBetween: 10,
            // slidesOffsetAfter: 50,
            freeMode: false,
            mousewheel: true,
            grabCursor: true,
            observer: true,
            // slidesPerView: 'auto',
            breakpoints: {
              480: {
                slidesPerView: 2
              },
              800: {
                slidesPerView: 3
              },
              1000: {
                slidesPerView: 4
              },
              2000: {
                slidesPerView: 5
              }
            }
          })
        }
      },
      componentUpdated: function (el, binding) {
        // console.log('updated', binding.value)
        if (binding.value.elementsLength > 1) {
          // eslint-disable-next-line no-unused-vars
          const swiper = new Swiper(el, {
            pagination: {
              el: el.childNodes[2],
              clickable: true,
              dynamicBullets: true
            },
            spaceBetween: 10,
            // slidesOffsetAfter: 50,
            freeMode: false,
            mousewheel: true,
            grabCursor: true,
            observer: true,
            // slidesPerView: 'auto',
            breakpoints: {
              480: {
                slidesPerView: 2
              },
              800: {
                slidesPerView: 3
              },
              1000: {
                slidesPerView: 4
              },
              2000: {
                slidesPerView: 5
              }
            }
          })
        }
      }
    }
  },
  created: function () {
    let vm = this
    EventBus.$on('gotServiceResponse', (e) => {
      if (e.messageId === vm.id) {
        vm.resultIsSuccess = e.responseText === 'Success'
        vm.cardState = vm.cardStates.ResultsView
        vm.resultString = e.responseText

        if (e.error === 'WrongPIN') {
          vm.resultString = 'Wrong E-PIN'
        }

        this.$ga.event('AddServiceResponse', e.responseText, e.serviceCode)
      }
    })
    if (this.entities.length > 0) {
      let serviceTypes = this.entities.filter((x) => x.type === 'Service')
      if (
        serviceTypes.length > 0 &&
        serviceTypes[0].resolution &&
        serviceTypes[0].resolution.values &&
        serviceTypes[0].resolution.values.length > 0
      ) {
        this.serviceFilter = serviceTypes[0].resolution.values[0].toLowerCase()
      }
    }
    this.billingType = this.user.userData.billing_type
  }
}
</script>
<style scoped>
.item {
  height: 12.5rem;
  padding: 20px 0;
  position: relative;
  width: 178px;
  background-color: white;
  border-radius: 10px;
}

.item p.green {
  width: 100%;
  position: absolute;
  top: 150px;
  left: 0;
}

.item p {
  font-size: 12.5px;
  margin-bottom: 5px;
  line-height: 1.2;
}

.item a {
  width: 60%;
  border-radius: 5px;
  height: 30px;
  line-height: 1.1;
  font-size: 30px;
  padding: 0;
  position: absolute;
  bottom: 20px;
  left: 19%;
  margin: 0;
}

.service-header {
  font-family: Avenir, Helvetica, sans-serif, sans-serif;
  font-size: 0.9rem;
  height: 35px;
}

.services-container {
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
  position: relative;
  background-color: white;
  padding: 10px 0;
  border-radius: 10px;
}

.item a.active {
  background: white;
  border: 1px solid #4db848;
  color: #4db848;
  font-size: 1rem;
  line-height: 1.8;
  cursor: unset;
}

.swiper-pagination-bullets {
  bottom: 0;
}

.add-service-button:hover,
.add-service-button:focus {
  background-color: #42853d;
}

.closable-filter {
  margin: 5px auto;
  display: inline-block;
  background-color: #b7b5b5;
  color: white;
  border-radius: 14px;
  padding: 4px 10px 1px;
}

.closable-filter i {
  position: relative;
  top: 2px;
  font-size: 1.1em;
}
</style>
