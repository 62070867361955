export function removeElement(el) {
  if (typeof el.remove !== 'undefined') {
    el.remove()
  } else if (typeof el.parentNode !== 'undefined' && el.parentNode !== null) {
    el.parentNode.removeChild(el)
  }
}

const isObject = (item) => typeof item === 'object' && !Array.isArray(item)
const mergeFn = (target, source, deep = false) => {
  if (deep || !Object.assign) {
    const isDeep = (prop) =>
        isObject(source[prop]) &&
        target !== null &&
        // eslint-disable-next-line no-prototype-builtins
        target.hasOwnProperty(prop) &&
        isObject(target[prop])
    const replaced = Object.getOwnPropertyNames(source)
        .map((prop) => ({ [prop]: isDeep(prop)
              ? mergeFn(target[prop], source[prop], deep)
              : source[prop] }))
        .reduce((a, b) => ({ ...a, ...b }), {})

    return {
      ...target,
      ...replaced
    }
  } else {
    return Object.assign(target, source)
  }
}
export const merge = mergeFn

export const setVueInstance = (Vue) => { VueInstance = Vue }

export let VueInstance

export function shallowEqual(object1, object2) {

  if (typeof object1 !== 'object') return object1 === object2

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}
