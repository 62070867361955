<template>

  <div class="inline-flex flex-col justify-center items-center" :class="{'w-full': fullWidth}"   :style="{'--bar-color': barColor}">
    <span class="font-semibold pb-2 text-xs">{{ labelTop }}</span>
    <div class="relative pt-1" :class="{'w-full': fullWidth}">
      <div class="overflow-hidden h-3 mb-4 text-xs flex rounded-xl bg-gray-200 dark:bg-gray-800 dark:bg-opacity-60" :class="{'w-full': fullWidth, 'w-40' : !fullWidth}">
        <div :style="{width: `${localValue}%`, transition: 'width 1s'}" class="h-full bar"></div>
      </div>
    </div>
    <span class="font-bold text-sm">{{ labelBottom }}</span>
    <span class="font-bold text-xs text-gray-600  dark:text-gray-300">{{ labelBottomSub }}</span>
  </div>
</template>

<script>
export default {
  name: 'ZProgressBarHorizontal',
  props: {
    value: {
      type: Number,
      default: () => 20
    },
    labelTop: {
      type: String,
      default: () => 'Top Label'
    },
    labelBottom: {
      type: String,
      default: () => 'Bottom Label'
    },
    labelBottomSub: {
      type: String,
    },
    fullWidth: {
      type: Boolean,
      default: () => false
    },
    barColor: {
      type: String,
      default: () => 'rgba(105, 190, 41, 1)'
    },

  },
  data: () => ({
    localValue: 0
  }),
  mounted() {
    this.$forceNextTick(() => {
      this.localValue = this.value
    })
  }
}
</script>

<style scoped>
.bar {
  background-color: var(--bar-color);
}
</style>