<template>
  <div class="bg-white rounded-md shadow-md">
    <div class="relative min-h-full text-center">
      <div class="pt-3 px-2">
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-white">
          {{ isPostpaid ? t('TotalBill') : t('Balance') }}
          <span class="font-bold">({{ channelData.userInfo.msisdn }})</span>
        </h3>
        <p class="mt-4 text-3xl font-semibold text-secondary">
          <span>KD {{ isPostpaid ? (unbilled + pastDue).toFixed(3) : balance }}</span>
        </p>

        <p class="py-4 dark:text-white" :dir="this.language === 'ar' ? 'rtl' : 'ltr'">
          <span v-if="isPostpaid">{{ daysTillEndOfMonth() }} {{ t('DaysUntil') }} </span>
          <span v-else>{{ t('ValidUntil') }} {{ formatDate(validity) }}</span>
        </p>

        <div class="flex justify-center max-w-xl mx-auto pb-3 space-x-4">
          <z-button
            v-if="isPostpaid"
            size="small"
            @click="openLink('https://www.kw.zain.com/en/autopay-web')"
            outlined
            class=""
            >{{ t('AUTOPAY') }}</z-button
          >
          <z-button
            size="small"
            @click="
              openLink(
                'https://pay.kw.zain.com/smartpay/paynow.smart?m=' + channelData.userInfo.msisdn
              )
            "
            >{{ isPostpaid ? t('PAYBILL') : t('RECHARGE') }}</z-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as moment from 'moment'
import ZButton from '../../zain-component-library/src/lib-components/ZButton'

export default {
  name: 'BalanceCell',
  components: { ZButton },
  props: [
    'unbilled',
    'pastDue',
    'balance',
    'validity',
    'localisationKeys',
    'language',
    'channelData'
  ],
  data() {
    return {
      userBillingType: typeof this.unbilled !== 'undefined' ? 'POST' : 'PREP',
      translations: {
        ValidUntil: {
          en: 'Valid Until',
          ar: 'صالح لغاية'
        },
        TotalBill: {
          en: 'Total Bill',
          ar: 'اجمالي الفاتورة'
        },
        Balance: {
          en: 'Balance',
          ar: 'الرصيد'
        },
        DaysUntil: {
          en: 'Days Until Your Next Bill',
          ar: 'يوم حتى فاتورتك القادمة'
        },
        AUTOPAY: {
          en: 'AUTOPAY',
          ar: 'الدفع التلقائي'
        },
        PAYBILL: {
          en: 'PAYBILL',
          ar: 'دفع الفاتورة'
        },
        RECHARGE: {
          en: 'RECHARGE',
          ar: 'إعادة تعبئة'
        }
      }
    }
  },
  computed: {
    ...mapState(['user']),
    isPostpaid() {
      return typeof this.unbilled !== 'undefined'
    }
  },
  methods: {
    formatDate(dt) {
      return moment(dt).format('YYYY-MM-DD')
    },
    daysTillEndOfMonth() {
      return moment().endOf('month').diff(moment(), 'days')
    },
    t(key) {
      if (this.translations?.[key]?.[this.language])
        return this.translations?.[key]?.[this.language]
      else return key
    },
    openLink(link) {
      window.open(link, '_blank').focus()
    }
  }
}
</script>
