<template>
  <!--
    Custom select controls like this require a considerable amount of JS to implement from scratch. We're planning
    to build some low-level libraries to make this easier with popular frameworks like React, Vue, and even Alpine.js
    in the near future, but in the mean time we recommend these reference guides when building your implementation:

    https://www.w3.org/TR/wai-aria-practices/#Listbox
    https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
  -->
  <div v-click-outside="closeOnClickOutside">
    <label :id="getUUID()" class="block text-sm font-medium">
      {{ label }}
    </label>
    <div class="relative">
      <button @click="() => isOpen = !isOpen" type="button"
              class="relative w-full bg-white dark:bg-opacity-10 border-b border-primary hover:border-black pl-3 pr-10 py-3 text-left cursor-pointer focus:outline-none sm:text-sm"
              aria-haspopup="listbox" :aria-expanded="isOpen.toString()" :aria-labelledby="getUUID()">
      <span class="flex items-center">
        <span class="block text-start truncate w-full font-bold h-5 select-none" style="overflow: visible !important;">
          <span v-if="multiSelect">

            <span v-if="$i18n && $i18n.locale">
              {{ selected.length }} {{ $t('OutOf') }} {{ items.filter(x => !x.isSelectAll).length }} {{ $t('Selected') }}
            </span>
            <span v-else>
              {{ selected.length }} out of {{ items.filter(x => !x.isSelectAll).length }} Selected
            </span>
            <span v-if="multiSelectLabelField && selected.length > 0">({{
                selected.map(x => x[multiSelectLabelField]).join(', ')
              }})</span>
          </span>
          <z-slot-component v-else-if="items.length > 0 && items.find(x => x.value === selected)"
                            :component="items.find(x => x.value === selected)"/>
        </span>

      </span>
        <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg class="transform transition-transform duration-300" :class="{'rotate-180': isOpen}"
               style="transform-origin: center" xmlns="http://www.w3.org/2000/svg" width="13.838" height="8.758"
               viewBox="0 0 13.838 8.758">
            <path id="Path_2439" data-name="Path 2439" d="M-12874.445-18625.674l6,6,6-6"
                  transform="translate(12875.364 18626.594)" fill="none" stroke="#d12b8a" stroke-width="2.6"/>
          </svg>
        </span>
      </button>

      <!--
        Select popover, show/hide based on select state.

        Entering: ""
          From: ""
          To: ""
        Leaving: "transition ease-in duration-100"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <ul v-if="screenWidth() >= 768 || alwaysDropdown" v-show="isOpen"
          class="absolute mt-1 w-full bg-white dark:bg-gray-800 shadow-lg max-h-72 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-10">
        <slot/>
      </ul>
      <z-modal v-else :active.sync="isOpen" can-cancel>
        <div class="p-5">
          <p>Select an option</p>
          <ul class="list-none">
            <slot/>
          </ul>
        </div>
      </z-modal>
    </div>

  </div>

</template>

<script>
import clickOutside from '../utils/directives/clickOutside'
import ZSlotComponent from '../utils/SlotComponent'
import ZModal from '../lib-components/ZModal'
import { shallowEqual } from '../utils/helpers'

export default {
  name: 'ZListBox',
  components: {
    ZModal,
    ZSlotComponent
  },
  provide() {
    return {
      parentInstance: this
    }
  },
  directives: { clickOutside },
  props: {
    label: {
      type: String
    },
    value: {
      type: [Number, String, Object, Array],
      default: 0
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    multiSelectLabelField: {
      type: String,
      default: null
    },
    alwaysDropdown: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    isOpen: false,
    selected: null,
    uuid: null,
    items: []
  }),
  methods: {
    selectItem(item) {
      if (this.multiSelect) {
        if (!this.selected) this.selected = []

        const isSelectAllListItem = (this.items.filter(x => x.isSelectAll))?.[0]
        if (item.isSelectAll) {
          if (this.selected.length === (isSelectAllListItem ? this.items.length - 1 : this.items.length)) {
            this.selected = []
            if (isSelectAllListItem) {
              isSelectAllListItem.isSelectAllSelected = false
            }
          } else {
            this.selected = this.items.filter(x => !x.isSelectAll).map(x => x.value)
            if (isSelectAllListItem) {
              isSelectAllListItem.isSelectAllSelected = true
            }
          }
        } else {
          // if item exists remove it, if not then add it
          if (this.selected.findIndex(x => shallowEqual(x, item.value)) > -1) {
            this.selected = this.selected.filter(x => !shallowEqual(x, item.value))
            if (isSelectAllListItem) {
              isSelectAllListItem.isSelectAllSelected = false
            }
          } else {
            this.selected.push(item.value)
            if (this.selected.length === (isSelectAllListItem ? this.items.length - 1 : this.items.length)) {
              if (isSelectAllListItem) {
                isSelectAllListItem.isSelectAllSelected = true
              }
            }
          }
        }
      } else {
        this.selected = item.value
        this.isOpen = false
      }
      this.$emit('input', this.selected)
    },
    getUUID() {
      if (!this.uuid) {
        this.uuid = 'listbox-' + this.$uuid()
      }
      return this.uuid
    },
    closeOnClickOutside() {
      this.isOpen = false
    },
    registerItem(item) {
      return this.items.push(item)
    },
    unregisterItem(item) {
      this.items = this.items.filter((i) => i !== item)
    },
    screenWidth() {
      return window.innerWidth
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.selected = val
      }
    }
  },
  beforeMount() {
    this.selected = this.value
  }
}
</script>
