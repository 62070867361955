/* eslint-disable no-unused-vars */
let CellTypeEnum
export default CellTypeEnum = {
  USER_TEXT: 0,
  BOT_TEXT: 1,
  BALANCE: 2,
  USAGE: 3,
  SERVICES: 4,
  ELIGIBLE_SERVICES: 5,
  BILL_DETAILS: 6,
  HELP: 7,
  LOGIN: 8,
  SUPPORT_TICKETS: 9,
  ROAMING_RATES: 10,
  PRODUCTS: 11,
  BRANCH_LOCATION: 12,
  LOGIN_RESPONSE: 13,
  LAST_PAYMENTS: 14,
  PLANS: 15,
  PRAYER_TIMES: 16,
  WEATHER: 17,
  WELCOME: 18,
  SYSTEM_MESSAGE: 19,
  TRANSFER_TO_AGENT: 20,
  CUSTOM_AD: 21,
  CAMPAIGNS: 22,
  CAMPAIGNS_RESPONSE: 23,
  SERVICES_RESPONSE: 24,
  GENERIC_ACTION_BUTTONS: 25,
  QUICK_ACTIONS: 26,
  EPIN_RESPONSE: 26,
  GENERIC_CARDS_CELL: 30,
  LAST_RECHARGES: 31,
  DATA_SHARING: 32,
  RESET_EPIN: 95,
  LOYALTY_CELL: 96,
  TYPING_INDICATOR: 97,
  SECRET_TICTACTOE: 98,
  CUSTOMER_SURVEY: 99,
  IGNORE: -1
}
