<template>
  <div class="text-center">
    <div class="animated fadeInLeft" v-show="currentCardState === 0 || currentCardState === 1">
      <div class="animated fadeInLeft" v-show="currentCardState === 0">
        <h2
          :style="language === 'ar' ? 'direction: rtl' : ''"
          style="font-size: 1.55em"
          class="bold padding-horizontal-1 padding-top-1">
          {{ cData.title }}
        </h2>
        <h3 style="font-size: 1.45em" class="padding-horizontal-1">
          <!--<i style="font-size: 0.8em; line-height: 1.1" class="fa fa-mobile"></i>-->
          {{ $store.state.user.userData.msisdn }}
        </h3>
      </div>
      <div class="animated fadeInLeft" v-show="currentCardState === 1">
        <a
          style="position: absolute; left: 20px; top: 3px"
          @click="
            () => {
              currentCardState = 0
            }
          "
          ><i class="fa fa-angle-left fa-3x black"></i
        ></a>
        <h4 style="font-size: 1.55em" class="padding-top-1">
          {{ $t('confirmation', language, strings) }}
        </h4>
      </div>
      <div v-if="offerImageIsLink">
        <img alt="offer" :src="cData.icon" style="width: 500px" />
      </div>
      <div v-else class="circle-cms margin-top-1">
        <!--<i style="font-size: 2em; color: white;" class="fa" :class="campaignData.icon"></i>-->
        <img alt="offer" :src="'../../assets/' + cData.icon" />
      </div>
      <p
        class="bold margin-top-1 padding-1"
        :style="language === 'ar' ? 'direction: rtl' : ''"
        style="font-size: 1em; line-height: 1.3">
        {{ campaignData[0].offerDescription }}
      </p>
      <div class="green" style="font-size: 1.5em; background: #efeff5">
        KD
        <span
          class="bold"
          style="font-family: Avenir, Helvetica, sans-serif, sans-serif; font-size: 1.5em"
          >{{ parseFloat(cData.cost).toFixed(3) }}</span
        >
        <!--<small style="font-size: 0.6em" class="display-block black">service price</small>-->
      </div>

      <div
        v-show="currentCardState === 0"
        class="animated fadeInLeft bold padding-left-1 padding-right-1 padding-bottom-1 grid-x grid-margin-x">
        <a
          :href="`https://www.kw.zain.com/${language}/offers`"
          target="_blank"
          class="cell small-6 medium-6 purple-outline-btn margin-top-1"
          style="font-size: 1.1em"
          >{{ $t('moreOffers', language, strings) }}</a
        >
        <button
          @click="sendCampaignFeedback"
          class="cell small-6 medium-6 purple-outline-btn inverted margin-top-1"
          style="font-size: 1.1em">
          <i
            style="font-size: 1em; color: white; display: initial; opacity: 0.5; margin-right: 2px"
            class="fa fa-check"></i>
          {{ $t('accept', language, strings) }}
        </button>
      </div>

      <div
        v-show="currentCardState === 1"
        class="animated fadeInLeft bold padding-left-1 padding-right-1 padding-bottom-1 grid-x grid-margin-x">
        <button
          @click="confrimSendCampaignFeedback"
          class="cell small-12 medium-12 purple-outline-btn inverted margin-top-1"
          style="font-size: 1.1em">
          <i
            style="font-size: 1em; color: white; display: initial; opacity: 0.5; margin: 0 5px 5px"
            class="fa fa-check"></i>
          {{ $t('confirm', language, strings) }}
        </button>
      </div>
    </div>
    <div v-show="currentCardState === 2" class="animated fadeInLeft">
      <h4 :style="language === 'ar' ? 'direction: rtl' : ''" class="padding-top-2">
        {{ $t('adding', language, strings) }}
      </h4>
      <i class="margin-vertical-2 fa fa-refresh fa-4x loading-spinner"></i>
    </div>

    <div v-show="currentCardState === 3" class="animated fadeInLeft">
      <h3 class="padding-top-1">
        {{
          this.resultIsSuccess ? $t('success', language, strings) : $t('failed', language, strings)
        }}
      </h3>

      <div v-if="resultIsSuccess">
        <div class="circle-cms margin-top-1" style="line-height: 4.8 !important">
          <i
            style="font-size: 2em; color: white"
            class="fa fa-check"
            :class="campaignData.icon"></i>
          <!--<img :src="'/static/' + cData.icon" />-->
        </div>
        <p class="bold padding-top-1">{{ cData.title }}</p>
        <p class="padding-bottom-1">{{ $t('successMessage', language, strings) }}</p>
      </div>
      <div v-else>
        <div class="circle-cms margin-top-1" style="line-height: 4.8 !important">
          <i
            style="font-size: 2em; color: white"
            class="fa fa-times"
            :class="campaignData.icon"></i>
          <!--<img :src="'/static/' + cData.icon" />-->
        </div>
        <p class="bold padding-top-1">{{ cData.title }}</p>
        <p class="padding-bottom-1">{{ $t('failedMessage', language, strings) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import EventBus from '../../eventBus'
import mixins from '../../mixins'

export default {
  name: 'CampaignCell',
  props: ['campaignData', 'language', 'id'],
  mixins: [mixins],
  data() {
    return {
      currentCardState: 0,
      cardStates: {
        MainView: 0,
        ConfirmView: 1,
        InProgressView: 2,
        ResultsView: 3
      },
      resultIsSuccess: false,
      strings: {
        confirmation: {
          en: 'CONFIRMATION',
          ar: 'تأكيد'
        },
        confirm: {
          en: 'CONFIRM ACTIVATION',
          ar: 'تأكيد تفعيل العرض'
        },
        accept: {
          en: 'ACCEPT',
          ar: 'موافق'
        },
        moreOffers: {
          en: 'MORE OFFERS',
          ar: 'المزيد من العروض'
        },
        success: {
          en: 'SUCCESS',
          ar: 'تم بنجاح'
        },
        failed: {
          en: 'ERROR',
          ar: 'حصل خطأ'
        },
        successMessage: {
          en: 'Your request has been received successfully, you will receive a confirmation SMS Shortly',
          ar: 'لقد تم استلام طلبك بنجاح وسوف تستلم رسالة للتأكيد قريباً'
        },
        failedMessage: {
          en: 'Dear Customer, There is an error with the offer activation',
          ar: 'عميلنا العزيز،  لم يتم تفعيل العرض بنجاح'
        },
        adding: {
          en: 'Adding...',
          ar: 'جاري التفعيل...'
        }
      }
    }
  },
  methods: {
    ...mapActions(['sendHiddenMessage']),
    confrimSendCampaignFeedback() {
      this.currentCardState = this.cardStates.InProgressView
      this.sendHiddenMessage({
        text:
          '<context campaign=' +
          this.campaignData[0].campaignID +
          '%' +
          this.campaignData[0].offerID +
          '%' +
          this.id +
          '>',
        locale: 'en',
        fromMessageId: this.id
      })
    },
    sendCampaignFeedback() {
      this.currentCardState = this.cardStates.ConfirmView
      this.$ga.event(
        'CampaignFeedback',
        this.cData.title,
        `campaignID: ${this.campaignData[0].campaignID}`
      )
    },
    offerImageIsLink() {
      return this.cData.icon.startsWith('http')
    }
  },
  computed: {
    cData() {
      return {
        cost: this.campaignData[0].campaignParams.filter((x) => x.name === 'OfferCost')[0].value,
        title: this.campaignData[0].campaignParams.filter(
          (x) => x.name === (this.language === 'en' ? 'EnglishOfferTitle' : 'ArabicOfferTitle')
        )[0].value,
        icon: this.campaignData[0].campaignParams.filter((x) => x.name === 'OfferIcon')[0].value
      }
    }
  },
  created: function () {
    EventBus.$on('gotCampaignResponse', (e) => {
      if (e.messageId === this.id) {
        this.resultIsSuccess = e.success
        this.currentCardState = this.cardStates.ResultsView

        this.$ga.event('CampaignFeedbackResponse', this.cData.title, `resultSuccess: ${e.success}`)
      }
    })
  }
}
</script>

<style scoped>
* {
  text-align: center;
}
.circle-cms {
  width: 75px;
  height: 75px;
  background: #621a6c;
  line-height: 5.8 !important;
  border-radius: 50%;
  font-size: 20px;
  text-align: center;
  margin: 0 auto;
}
.inverted:hover i {
  color: #d02486 !important;
}

button {
  cursor: pointer;
}

img {
  vertical-align: unset;
  width: 46px;
}
.loading-spinner {
  animation: spinIt 1.5s infinite linear;
}

@keyframes spinIt {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.purple-outline-btn {
  padding: 0.85em 0;
}
</style>
