<template>
  <div
    class="text-center"
    style="
      background-color: rgba(255, 255, 255, 0.2);
      color: white;
      border-radius: 10px;
      padding: 10px 0;
    ">
    <a target="_blank" :href="`https://www.kw.zain.com/${$store.state.language}/feb`">
      <h2 class="display-inline-block loyalty-points font-bold">
        {{ parseInt(loyaltyData.balance) }}
      </h2>
      <div class="display-inline-block loyalty-text">
        {{ loyaltyData.unit_name }} {{ loyaltyData.scheme_name }}<br />
        <strong class="display-inline-block">{{ loyaltyData.short_name }}</strong>
      </div>
      <div class="display-inline-block loyalty-chart">
        <circle-chart
          :percent="getPercentage()"
          :circleText="`<strong>${loyaltyData.days_left}</strong><br />DAYS`">
        </circle-chart>
      </div>
      <!--<i class="fa fa-chevron-right"></i>-->
    </a>
  </div>
</template>

<script>
import CircleChart from '../CircleChart'
import moment from 'moment'

export default {
  name: 'LoyaltyCell',
  props: ['loyaltyData'],
  components: {
    CircleChart
  },
  methods: {
    getPercentage() {
      if (this.loyaltyData) {
        let startDt = moment(this.loyaltyData.start_date)
        let endDt = moment(this.loyaltyData.end_date)

        let totalDays = endDt.diff(startDt, 'days')
        // console.log('totalDays', totalDays)
        return Math.round((this.loyaltyData.days_left / totalDays) * 100)
      } else return 0
    }
  }
}
</script>

<style scoped>
.loyalty-points {
  /*padding: 5px 5px 5px 5px;*/
  vertical-align: middle;
  line-height: 1;
  /*border-right: 2px rgba(255, 255, 255, 0.35) solid;*/
  margin: 7px 20px 0 0;
}

.loyalty-text {
  line-height: 1.1;
  padding-top: 8px;
  vertical-align: middle;
  margin-right: 20px;
  font-size: 0.7em;
}

.loyalty-chart {
  vertical-align: middle;
  padding-right: 5px;
}

a {
  color: white;
}
</style>
