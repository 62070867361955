<template>
  <button type="button" :class="classes" v-on="$listeners" v-bind="$attrs"
          class="transition ease-in duration-200 focus:outline-none focus:ring-2 focus:ring-offset focus:ring-blue-200 focus:ring-blue-400 text-center rounded-md">
    <slot v-if="!isLoading"/>
    <svg v-else class="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
         viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    outlined: {
      type: Boolean,
      default: () => false,
    },
    borderless: {
      type: Boolean,
      default: () => false,
    },
    size: {
      type: String,
      default: () => 'medium'
    },
    type: {
      type: String,
      default: () => 'primary',
    },
    paddingless: {
      type: Boolean,
      default: () => false
    }
  },
  name: 'ZButton',
  computed: {
    classes() {
      let c = []

      switch (this.size) {
        case 'xsmall': {
          c = c.concat(this.paddingless ? ['text-xs'] : ['py-2', 'px-3', 'text-xs'])
          break
        }
        case 'small': {
          c = c.concat(this.paddingless ? ['text-sm'] : ['py-2', 'px-3', 'text-sm'])
          break
        }
        case 'medium': {
          c = c.concat(this.paddingless ? ['text-base'] : ['py-2', 'px-4', 'text-base'])
          break
        }
        case 'large': {
          c = c.concat(this.paddingless ? ['text-xl'] : ['py-2', 'px-4', 'text-xl'])
          break
        }
      }

      if (this.$attrs?.disabled === '' || this.$attrs?.disabled) {
        c = [...c, 'cursor-not-allowed', 'bg-gray-200', 'text-gray-400']
      } else {
        if (this.borderless) {
          c = [...c, 'underline', 'text-gray-600', 'hover:text-primary']
        } else {
          c = [...c, 'hover:shadow-lg']
          if (!this.outlined) {
            c = [...c, this.types?.[this.type], 'text-white' ,'hover:bg-transparent', 'border']
          }
          if (this.outlined) {
            c = [...c, this.outlinedTypes?.[this.type], 'border', 'bg-transparent', 'hover:text-white', 'dark:text-white']
          }
        }
      }

      return c
    },
    isLoading() {
      return this.$attrs?.loading === '' || this.$attrs.loading
    }
  },
  data() {
    return {
      types: {
        primary: 'bg-primary hover:text-primary border-primary',
        secondary: 'bg-secondary hover:text-secondary border-secondary',
        white: 'bg-transparent hover:text-primary border-white'
      },
      outlinedTypes: {
        primary: 'border-primary text-primary hover:bg-primary',
        secondary: 'border-secondary text-secondary hover:bg-secondary',
        white: 'border-white text-white hover:bg-primary'
      }
    }
  }
}
</script>
