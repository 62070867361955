<template>
  <div id="app">
    <chat-view />
    <div
      @click="$store.state.fullScreenImg = false"
      v-if="$store.state.fullScreenImg"
      class="fullscreen animated fadeInLeft">
      <img :src="$store.state.fullScreenImgSrc" alt="" />
    </div>
  </div>
</template>

<script>
import ChatView from './components/ChatView'

export default {
  name: 'app',
  components: { ChatView },
  beforeMount() {
    const lang = getParameterByName('language')
    if (lang) {
      if (lang === 'ar') {
        this.$store.state.chatModule.language = 'ar'
      }
    }
    const intent = getParameterByName('intent')

    if (intent) {
      this.$store.state.chatModule.initialIntent = intent
    }
  }
}

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}

.fullscreen {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 100000;
}

.fullscreen img {
  height: 100%;
  display: block;
  margin: auto auto;
  width: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-height: 100vh;
}
</style>
