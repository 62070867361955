<template>
  <div class="text-center grid-x">
    <div
      :class="{
        'small-12': supportData.length === 1,
        'small-6': supportData.length === 2,
        'small-4': supportData.length === 3
      }"
      class="cell padding-1"
      v-for="(sup, index) in supportData"
      v-bind:key="index">
      <div class="margin-top-1 circle">
        <i style="line-height: 2.1" class="fa fa-3x" :class="sup.icon"></i>
      </div>
      <a
        @click="$ga.event('LiveAgentChatRequest', language)"
        class="text-underline"
        :href="language === 'en' ? sup.link : sup.link_ar"
        target="_blank"
        ><h6 class="padding-top-1">{{ language === 'en' ? sup.title : sup.title_ar }}</h6></a
      >
      <p>{{ language === 'en' ? sup.text : sup.text_ar }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Agent',
  props: {
    supportData: {
      type: Array,
      default() {
        return [
          {
            icon: 'fa-comments',
            title: 'Talk to Support Agent',
            title_ar: 'تكلم مع ممثل الخدمة',
            text: '(Will open in a new window)',
            text_ar: '(ستفتح في نافذة جديدة)',
            link: 'https://www.kw.zain.com/en/#modal-live-chat',
            link_ar: 'https://www.kw.zain.com/ar/#modal-live-chat'
          }
          // ,
          // {
          //   icon: 'fa-globe',
          //   title: 'Call from outside Kuwait',
          //   text: '(+965) 97107107',
          //   link: 'tel:0096597107107'
          // }
        ]
      }
    },
    localizationKeys: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: String,
      default() {
        return ['language']
      }
    }
  }
}
</script>
<style scoped></style>
