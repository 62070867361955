<template>
  <div class="p-4 bg-white rounded-md text-left">
    <div class="paycell" v-for="(recharge, index) in recharges" v-bind:key="index">
      <h6 class="rate-header">
        {{ formatDate(recharge.timestamp) }}
        <span style="float: right">{{ recharge.purpose }}</span>
      </h6>
      <div class="rateContainer">
        <h6>{{ recharge.rechargeType }}</h6>
        KD <strong>{{ parseFloat(recharge.amount).toFixed(3) }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'

export default {
  props: ['recharges'],
  name: 'LastRecharges',
  methods: {
    formatDate(dt) {
      return moment(dt).format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped>
.paycell:not(:last-child) {
  margin-bottom: 20px;
}

.rateContainer {
  background: #f3f3f3;
  padding: 5px;
  border: 1px rgb(255, 255, 255) solid;
  font-size: 0.8rem;
}

.rate-header {
  background-color: rgb(182, 97, 182);
  padding: 5px;
  color: white;
  margin: 0;
  font-weight: bold;
  border: 1px rgb(255, 255, 255) solid;
  font-size: 0.9rem;
}
</style>
