<template>
  <div>
    <small :dir="language === 'ar' ? 'rtl' : 'ltr'"
      >{{ msisdn }} {{ nickname ? `- ${nickname}` : '' }}</small
    >
    <br />
    <div class="flex justify-between my-0.5">
      <div>
        <h4 v-if="isUnlimited" class="inline-block">
          <span class="text-xl font-semibold">{{ $t('Unlimited', language, strings) }}</span>
        </h4>
        <h4 v-else style="display: inline-block">
          <span class="text-xl font-semibold">{{ used }}</span
          >/{{ threshold }} {{ unit }}
        </h4>
      </div>
      <i class="fa fa-2x fa-wifi green pull-right" aria-hidden="true"></i>
    </div>
    <div v-if="!isUnlimited" class="round progress" role="progressbar" aria-valuemax="100">
      <div
        class="progress-meter purple-bg"
        :style="{ width: (used / threshold) * 100 + '%' }"></div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins'
export default {
  props: ['msisdn', 'isUnlimited', 'language', 'used', 'threshold', 'unit', 'nickname'],
  name: 'UsageComponent',
  mixins: [mixins],
  data() {
    return {
      strings: {
        Unlimited: {
          en: 'Unlimited ∞',
          ar: 'غير محدود ∞'
        }
      }
    }
  }
}
</script>

<style>
.progress {
  height: 0.5rem;
  margin-bottom: 0.6rem;
  background-color: #e3e3e3;
}

.round,
.round .progress-meter {
  border-radius: 10px;
}

.progress-meter {
  transition: width 1.25s ease-in-out;
}

.purple-bg {
  background-color: #b46bcd;
}

.green-bg {
  background-color: #7bc440;
}

.purple {
  color: #8e45a8;
}

.pink {
  color: #d02486;
}

.green {
  color: #7bc440;
}

.progress {
}

.progress-meter {
  position: relative;
  display: block;
  width: 0;
  height: 100%;
}
</style>
