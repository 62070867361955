<template>
  <div class="fixed bottom-0 h-14 w-full">
    <input
      ref="chat-input"
      aria-label="SendMessage"
      type="text"
      v-model="msgText"
      v-on:keyup.enter="onNewMessage"
      class="w-full h-full p-3 text-white focus:outline-none focus:ring-2 rounded-none"
      style="background-color: #363636"
      :placeholder="language === 'en' ? 'Send Message' : 'أكتب الرسالة'" />
    <a v-on:click="onNewMessage" class="absolute right-4 bottom-3.5">
      <i
        class="fa fa-paper-plane text-white text-opacity-30"
        style="font-size: 1.5em"
        aria-hidden="true" />
    </a>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'UserInputBar',
  data() {
    return {
      msgText: ''
    }
  },
  computed: {
    ...mapState('chatModule', ['quickMenuEmoji', 'language'])
  },
  methods: {
    ...mapMutations('chatModule', ['setMessageText']),
    ...mapActions('chatModule', ['sendChatMessage']),
    onNewMessage() {
      this.sendChatMessage(this.msgText)
      this.msgText = ''
      this.$refs['chat-input'].blur()
      // this.playEmptySound()
    }
  }
}
</script>
