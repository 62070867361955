<template>
  <div class="surv" :dir="language === 'ar' ? 'rtl' : 'ltr'">
    <!--<div style="width: 14%; padding: 10px; display: inline-block; vertical-align: middle;">-->
    <!--<img alt="zBot" src="/static/ChatBot-Icon-s-white.png">-->
    <!--</div>-->
    <img alt="zBot" src="../../assets/ChatBot-Icon-s-white.png" />
    <strong>{{ title }}</strong
    ><br />
    <small>{{ subText }}</small>
    <br />
    <a @click="hide()">{{ this.language === 'en' ? 'Not Today' : 'ليس اليوم' }}</a>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <a :href="link" target="_blank" @click="takeSurvey()">{{
      this.language === 'en' ? 'Accept' : 'موافق'
    }}</a>
  </div>
</template>

<script>
export default {
  name: 'Survey',
  methods: {
    hide() {
      this.$store.commit('toggleHideSurveyCell', true)
      this.$ga.event('Survey', 'HideSurvey', this.language)
    },
    takeSurvey() {
      this.$store.commit('toggleHideSurveyCell', true)
      this.$ga.event('Survey', 'TakeSurveyLink', this.language)
    }
  },
  computed: {
    language() {
      return this.$store.state.language
    },
    title() {
      return this.language === 'en' ? 'May you help us to improve zBot?' : 'هل قُمت بمساعدتك اليوم؟'
    },
    subText() {
      return this.language === 'en'
        ? 'Please take a few moments to respond to a short survey evaluating your experience with me.'
        : 'أرجو أن تُخصص دقائق من وقتك للإجابة على استبيان قصير لتُقيّم من خلاله تجربتك معي.'
    },
    link() {
      return this.language === 'en'
        ? 'http://ecol-zain.responsetek.com/collection/collection.aspx?cguid=fdc-e1099dcdb065&langid=1&optionConsumerCustom1=e23-38e243135b04&optionConsumerCustom2=59b-3efdf4649782'
        : 'http://ecol-zain.responsetek.com/collection/collection.aspx?cguid=fdc-e1099dcdb065&langid=22&optionConsumerCustom1=e23-38e243135b04&optionConsumerCustom2=c88-9b7d13a41eb5'
    }
  }
}
</script>

<style scoped>
.surv {
  background-color: rgba(3, 3, 3, 0.22);
  border-radius: 5px;
  padding: 5px;
  font-size: 1.15em;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

a {
  color: white;
  text-decoration: underline;
}

a:hover {
  color: #d02486;
}

img {
  display: inline-block;
  width: 20px;
  position: relative;
  top: -3px;
}
</style>
