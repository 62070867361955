<template>
  <div>
    <ul :class="[...getOuterClasses(), ' ', customOuterClasses]">
      <li v-for="(tab, index) in sortedItems" :key="index"
          :tabindex="index === activeTabInternal ? -1 : 0"
          class="px-4 py-2 relative flex-1 text-center" @click="setActiveTab(index)"
          :class="getInnerClasses(index)">
        <z-slot-component v-if="tab.$scopedSlots.header" :component="tab" name="header" tag="a"/>
        <div class="flex justify-center items-center gap-2" v-else>
          <div class="flex justify-center items-center">
            <span :class="tab.$props.icon"/>
          </div>
          {{ tab.$props.label }}
        </div>
      </li>
    </ul>
    <div class="relative flex flex-col" :class="{'overflow-hidden': isTransitioning}">
      <slot/>
    </div>
  </div>
</template>

<script>
import ZSlotComponent from '../utils/SlotComponent'

export default {
  name: 'ZTabs',
  components: {
    ZSlotComponent
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    isToggle: {
      type: Boolean,
      default: false
    },
    customOuterClasses: {
      type: String,
      default: ''
    }
  },
  computed: {
    sortedItems() {
      let items = this.items
      items.sort((a, b) => a.sequence - b.sequence)
      return items
    }
  },
  data: () => ({
    items: [],
    activeTabInternal: 0,
    isTransitioning: false
  }),
  watch: {
    value: {
      handler: function (val) {
        this.setActiveTab(val)
      }
    }
  },
  methods: {
    registerItem(item) {
      return this.items.push(item)
    },
    unregisterItem(item) {
      this.items = this.items.filter((i) => i !== item)
    },
    setActiveTab(tabIndex) {
      if (tabIndex === this.activeTabInternal) return

      const oldIndex = this.activeTabInternal
      const newIndex = tabIndex

      this.items[oldIndex].deactivate(newIndex)
      this.items[newIndex].activate(oldIndex)

      this.activeTabInternal = newIndex

      this.$emit('input', tabIndex)
    },
    getOuterClasses() {
      if (this.isToggle) {
        return 'list-none rounded-sm border border-gray-300 flex items-stretch justify-center text-primary'
      } else {
        return 'list-none flex items-stretch justify-center border-b border-gray-300'
      }
    },
    getInnerClasses(index) {
      if (this.isToggle) {
        let nonActiveClasses = 'border-gray-300 cursor-pointer hover:text-white hover:bg-primary'
        if (index !== this.items.length - 1) {
          nonActiveClasses = nonActiveClasses.concat(' border-r')
        }
        return {
          'text-white bg-primary': index === this.activeTabInternal,
          [nonActiveClasses]: index !== this.activeTabInternal
        }
      } else {
        return {
          'text-primary border-b border-primary font-bold': index === this.activeTabInternal,
          'text-gray-600 cursor-pointer border-b hover:border-black dark:hover:border-gray-400': index !== this.activeTabInternal
        }
      }
    }
  },
  mounted() {
    this.setActiveTab(this.value)
  }
}
</script>
