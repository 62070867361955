export default {
  props: {
    type: {
      type: [String, Object],
    },
    label: {
      type: String
    },
  },
  computed: {
    getType() {
      let newType
      if (this.type)
        newType = this.type

      if (this.$parent.$data.isField && this.$parent.type) {
        newType = this.$parent.type
      }

      if (newType && typeof (newType) === 'string') {
        return newType
      } else if (newType && typeof (newType) === 'object') {
        for (const item in newType) {
          if (Object.prototype.hasOwnProperty.call(newType, item)) {
            if (newType[item]) {
              return item
            }
          }
        }
      }
    },
    getLabel() {
      if (this.label)
        return this.label

      if (this.$parent.$data.isField && this.$parent.label) {
        return this.$parent.label
      }
    },
  }
}
