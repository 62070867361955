<template>
  <div :class="isActive? extraClasses : ''">
    <transition :name="animation">
      <div
          class="is-active"
          :class="[{ 'is-full-page': displayInFullPage }, { 'loading-overlay': !localInlineSize }]"
          v-if="isActive">
        <div class="loading-background"
             :class="hasBackground ? backgroundClasses : ''"
             @click="cancel"/>
        <svg class="animate-spin -ml-1 mr-3 h-10 text-primary" :class="localInlineSize ? localInlineSize : 'w-105'" xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    </transition>
  </div>
</template>

<script>

function removeElement(el) {
  if (typeof el.remove !== 'undefined') {
    el.remove()
  } else if (typeof el.parentNode !== 'undefined' && el.parentNode !== null) {
    el.parentNode.removeChild(el)
  }
}


export default {
  name: 'ZLoading',
  // deprecated, to replace with default 'value' in the next breaking change
  model: {
    prop: 'active',
    event: 'update:active'
  },
  props: {
    active: Boolean,
    programmatic: Boolean,
    container: [Object, Function, window.HTMLElement],
    isFullPage: {
      type: Boolean,
      default: true
    },
    animation: {
      type: String,
      default: 'fade'
    },
    canCancel: {
      type: Boolean,
      default: false
    },
    onCancel: {
      type: Function,
      default: () => {
      }
    },
    hasBackground: {
      type: Boolean,
      default: true
    },
    backgroundClasses: {
      type: String,
      default: 'bg-white'
    },
    extraClasses: String,
    inlineSize: {
      //Useful when you need a ZLoading inline of the DOM
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isActive: this.active || false,
      displayInFullPage: this.isFullPage,
      localInlineSize: ''
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    isFullPage(value) {
      this.displayInFullPage = value
    }
  },
  methods: {
    /**
     * Close the Modal if canCancel.
     */
    cancel() {
      if (!this.canCancel || !this.isActive) return

      this.close()
    },
    /**
     * Emit events, and destroy modal if it's programmatic.
     */
    close() {
      this.onCancel.apply(null, arguments)
      this.$emit('close')
      this.$emit('update:active', false)

      // Timeout for the animation complete before destroying
      if (this.programmatic) {
        this.isActive = false
        setTimeout(() => {
          this.$destroy()
          removeElement(this.$el)
        }, 150)
      }
    },
    /**
     * Keypress event that is bound to the document.
     */
    keyPress({ key }) {
      if (key === 'Escape' || key === 'Esc') this.cancel()
    },
    getSize() {
      switch (this.inlineSize) {
        case 'xsmall': {
          this.localInlineSize = 'w-4'
          break
        }
        case 'small': {
          this.localInlineSize = 'w-7'
          break
        }
        case 'medium': {
          this.localInlineSize = 'w-10'
          break
        }
        case 'large': {
          this.localInlineSize = 'w-15'
          break
        }
        default:
          break
      }
    }
  },
  created() {
    if (typeof window !== 'undefined') {
      document.addEventListener('keyup', this.keyPress)
    }
  },
  beforeMount() {
    // Insert the Loading component in body tag
    // only if it's programmatic
    if (this.programmatic) {
      if (!this.container) {
        document.body.appendChild(this.$el)
      } else {
        this.displayInFullPage = false
        this.$emit('update:is-full-page', false)
        this.container.appendChild(this.$el)
      }
    }
  },
  mounted() {
    if (this.programmatic) this.isActive = true
    if (this.inlineSize) this.getSize()
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('keyup', this.keyPress)
    }
  }
}
</script>

<style>

.loading-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 9;
}

.loading-overlay.is-active {
  display: flex;
}

.loading-overlay.is-full-page {
  position: fixed;
  z-index: 1000;
}

.loading-overlay.is-full-page .loading-icon:after {
  top: calc(50% - 2.5em);
  left: calc(50% - 2.5em);
  width: 5em;
  height: 5em;
}

.loading-overlay .loading-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  /*background: rgb(255, 255, 255);*/
}

.dark .loading-background {
  background: rgb(64, 64, 64);
}
</style>
