import * as DirectLine from 'botframework-directlinejs'
import store from '../store/index'
import config from '../config'

const axios = require('axios').default
let directLine

const init = async (token) => {
  const url = new URL(location.href)
  let initialNumber = url.searchParams.get('m') ?? null

  try {
    let res
    if (token) {
      res = await reconnect(token)
    }

    if (!token || !res?.isSuccess) {
      res = await getNewToken()
    }

    directLine = new DirectLine.DirectLine({
      secret: res.token,
      webSocket: false,
      domain: config.value('botChannelsHost')
    })
    directLine.setUserId(res.userId)
    return { userId: res.userId, token: res.token }
  } catch (e) {
    console.error(e)
  } finally {
    if (initialNumber && initialNumber.length === 8) {
      await store.commit('chatModule/setInitialNumber', initialNumber)
    }
  }
}

const reconnect = async (token) => {
  const headers = {
    authorization: `Bearer ${token}`
  }
  return (await axios.get(`${config.value('botChannelsHost')}/resume`, { headers })).data
}

const getNewToken = async () => {
  return (await axios.get(`${config.value('botChannelsHost')}/tokens/generate`)).data
}

function post(activityData, cb) {
  if (store.state.debug) {
    console.log('Sending!')
    console.log(activityData)
  }

  try {
    directLine.postActivity(activityData).subscribe(cb)
  } catch (e) {
    post(activityData, cb)
  }
}

function subscribe(messageHandler) {
  directLine.activity$
    .filter((activity) => activity.type === 'message' || activity.type === 'typing')
    .filter((activity) => activity.from.role === 'bot' || activity.from.role === 'agent')
    .subscribe(messageHandler)
}

function subscribeToStatusUpdates(connectionStatusHandler) {
  directLine.connectionStatus$.subscribe(connectionStatusHandler)
}

const dl = { init, post, subscribe, subscribeToStatusUpdates }

export default dl
