<template>
  <div>
    <div class="card-header top-radius black align-left">RESET E-PIN</div>
    <div v-if="cardState === cardStates.enterDetails">
      <div class="card-body">
        <label
          >Civil ID or Passport
          <input
            placeholder="Enter Civil ID or Passport"
            required
            v-model="civilIdOrPassport"
            type="text"
            style="width: 200px; margin: 0 auto 10px" />
        </label>
        <label
          >New E-PIN
          <input
            placeholder="Enter new E-PIN"
            required
            v-model="newPin"
            type="text"
            style="width: 200px; margin: 0 auto 10px"
            maxlength="4" />
        </label>

        <label
          >Confirm New E-PIN
          <input
            placeholder="Confirm new E-PIN"
            required
            v-model="confirmNewPin"
            type="text"
            style="width: 200px; margin: 0 auto 10px"
            maxlength="4" />
        </label>

        <div style="padding-bottom: 10px; color: red" v-if="epinError">{{ epinError }}</div>
      </div>
      <div @click="resetPin()" class="purple-outline-btn inverted width-100 margin-top-1">
        RESET E-PIN
      </div>
    </div>
    <div v-else class="card-body">
      <br />
      <br />
      <span v-if="cardState === cardStates.resetInProgress"> Resetting E-PIN.... </span>
      <span v-if="cardState === cardStates.resultSuccess"> E-PIN was reset Successfully </span>
      <span v-if="cardState === cardStates.resultFailed">
        Error while resetting E-PIN {{ resultError }} <br /><br />
        <a class="purple-outline-btn inverted" @click="cardState = cardStates.enterDetails">BACK</a>
      </span>
      <br />
      <br />
      <br />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import EventBus from '../../eventBus'

export default {
  name: 'ResetEpin',
  props: {
    language: {
      type: String,
      default: 'en'
    }
  },
  data: () => {
    return {
      civilIdOrPassport: '',
      newPin: '',
      confirmNewPin: '',
      epinError: '',
      cardStates: {
        enterDetails: 0,
        resetInProgress: 1,
        resultSuccess: 2,
        resultFailed: 3
      },
      cardState: 0,
      resultError: '',
      id: Math.random().toString(36).substr(2, 10)
    }
  },
  methods: {
    ...mapActions(['sendHiddenMessage']),
    resetPin() {
      if (!this.civilIdOrPassport || !this.newPin || !this.confirmNewPin) {
        this.epinError = 'Please fill all the fields above'
        return
      }

      if (this.newPin !== this.confirmNewPin) {
        this.epinError = 'New E-PIN and confirm E-PIN are not matching'
        return
      }

      this.epinError = ''
      this.cardState = this.cardStates.resetInProgress

      this.sendHiddenMessage({
        text: `<context resetPin=${this.civilIdOrPassport}%${this.newPin}%${this.id}>`,
        locale: this.language,
        fromMessageId: this.id
      })
    }
  },
  created() {
    let vm = this
    EventBus.$on('gotEpinResponse', (e) => {
      if (e.messageId === vm.id) {
        if (e.responseText === 'Success') {
          this.cardState = this.cardStates.resultSuccess
        } else {
          this.cardState = this.cardStates.resultFailed
          this.resultError = e.error
        }
      }
    })
  }
}
</script>
<style>
.card-header {
  background-color: #eeeeee;
  padding: 10px 10px 0;
  text-align: left;
}

.top-radius {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-body {
  background-color: white;
  color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
}
</style>
