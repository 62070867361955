import Vue from 'vue'
import App from './App'
import store from './store'
import ZainComponentLibrary from './zain-component-library/src/entry.esm'
import './assets/plugins/font-awesome/font-awesome.min.css'
import 'animate.css'
import 'swiper/swiper-bundle.css'
import './assets/css/styles.css'
import './assets/css/icons.css'
import './assets/tailwind.css'
import VueGtag from 'vue-gtag'
import VSwitch from '@/components/helpers/v-switch-case'

Vue.use(VueGtag, { config: { id: 'G-E5N9055J5J' } })
Vue.use(ZainComponentLibrary)
Vue.use(VSwitch)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store: store,
  template: '<App/>',
  components: { App }
})
