<template>
  <div :class="{'flex items-center': horizontal}">
    <label :for="'cc' + $uuid()" class="text-sm font-medium block"  :class="{'flex items-center me-3': horizontal}">
      {{ getLabel }}
      <slot name="label"/>
    </label>
    <div class="relative">
      <div class="flex items-center">
        <span v-if="prefix" class="absolute start-2 font-bold text-secondary text-base py-2.5 mt-1">{{ prefix }}</span>
        <span v-if="suffix" class="absolute end-2 font-bold text-secondary text-base py-2.5 mt-1">{{ suffix }}</span>
        <input v-if="inputType !== 'textarea'"
               :dir="direction"
               :id="'cc' + $uuid()"
               :placeholder="placeholder"
               :name="getLabel"
               v-bind="$attrs"
               :value="computedValue"
               :style="inputStyle"
               @input="onInput"
               :type="inputType"
               :class="[classes, inputClasses]"/>
        <textarea v-else
                  :dir="direction"
                  :value="computedValue"
                  @input="onInput"
                  :class="[classes, inputClasses]"/>
        <div class="absolute end-2 items-end">
          <!--        <span v-if="this.getType === 'danger'" class="icon-Info text-danger"></span>-->
          <!--        <span v-else-if="this.getType === 'success'" class="icon-check-mark-button text-green"></span>-->
          <slot name="right"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from '../utils/mixins/formMixin'

export default {
  inheritAttrs: false,
  name: 'ZInput',
  mixins: [formMixin],
  props: {
    direction: {
      type: String,
      default: ''
    },
    value: [String, Number],
    outlined: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: 'text'
    },

    placeholder: {
      type: String
    },
    inputClasses: String,
    inputStyle: String,
    prefix: String,
    suffix: String,
    horizontal: Boolean
  },
  computed: {
    computedValue: {
      get() {
        return this.localValue
      },
      set(value) {
        this.localValue = value
        this.$emit('input', value)
        this.$emit('change', value)
      }
    },
    inputListeners: function () {
      let vm = this
      return Object.assign({},
          this.$listeners,
          {
            input: function (event) {
              vm.$emit('input', event.target.value)
            }
          }
      )
    },
    classes() {
      return [
        {
          'bg-gray-100 dark:bg-gray-800 border rounded-md': !this.outlined && !this.borderless,
          'border-primary border-b border-t-0 border-l-0 border-r-0 px-0': this.outlined && !this.borderless,
          'bg-white text-primary border-0': this.borderless,
        },
        { 'cursor-not-allowed': this.$attrs.disabled === '' || this.$attrs.disabled },
        { 'border border-gray-200 dark:border-gray-500': !this.borderless },
        'mt-1 w-full',
        'py-2.5 dark:placeholder-gray-700 focus:border-transparent focus:outline-none',
        'focus:ring focus:ring-blue-200 focus:border-blue-400',
        {
          'border-danger': this.getType === 'danger',
          'border-green': this.getType === 'success'
        },
        {
          'ps-10 text-left': this.prefix,
          'pe-10 text-left': this.suffix
        }, {
          'pe-7 text-start': this.hasRightSlot
        }
      ]
    },
    hasRightSlot() {
      return !!this.$scopedSlots.right
    }
  },
  data() {
    return {
      localValue: this.value,
      id: 0,
    }
  },
  watch: {
    value(val) {
      this.localValue = val
    }
  },
  methods: {
    onInput(event) {
      this.computedValue = event.target.value
    }
  },
  beforeMount() {
    this.id = this.$uuid()
  }
}
</script>

<style scoped>
[dir="rtl"] label input {
  text-align: right !important;
}
</style>
