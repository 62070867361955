<template>
  <li>
    <div
      :cellText="text"
      v-bind="payload"
      :is="'c-' + payload.cellType.toString().padStart(2, '0')"></div>
  </li>
</template>
<script>
import PlansCell from './PlansCell'
import BalanceCell from './BalanceCell'
import UsageCell from './UsageCell'
import HelpCell from './HelpCell'
import BranchCell from './BranchCell'
import AddServicesCell from './AddServicesCell'
import ProductsCell from './ProductsCell'
import RoamingRatesCell from './RoamingRatesCell'
import LoginCell from './LoginCell'
import BillDetailsCell from './BillDetails'
import LastPaymentsCell from './LastPayments'
import LastRechargesCell from './LastRecharges'
import PrayerTimesCell from './PrayerTimesCell'
import WelcomeCell from './WelcomeCell'
import TextCell from './TextCell'
import SystemMessageCell from './SystemMessageCell'
import AgentCell from './AgentCell'
import CampaignCell from './CampaignCell'
import TTTCell from './SecretTTT'
import TypingCell from './TypingIndicatorCell'
import CustomAdCell from './CustomAdCell'
import SurveyCell from './SurveyCell'
import LoyaltyCell from './LoyaltyCell'
import GenericActionButtonsCells from './GenericActionButtonsCell'
import QuickActionsCell from './QuickActionsCell'
import ResetEpinCell from './ResetEpinCell'
import GenericCardsCell from './GenericCardsCell'
import DataSharingCell from './DataSharingCell/DataSharingCell'

export default {
  name: 'Cell',
  props: {
    user: { type: String },
    text: { type: String },
    language: { type: String },
    payload: {
      type: Object,
      default: function () {
        return { cellType: 0, data: {} }
      }
    }
  },
  // corresponds to cell type enum (CellType.js)
  components: {
    'c-00': TextCell,
    'c-01': TextCell,
    'c-02': BalanceCell,
    'c-03': UsageCell,
    'c-04': AddServicesCell,
    'c-05': AddServicesCell,
    'c-06': BillDetailsCell,
    'c-07': HelpCell,
    'c-08': LoginCell,
    'c-10': RoamingRatesCell,
    'c-11': ProductsCell,
    'c-12': BranchCell,
    'c-14': LastPaymentsCell,
    'c-15': PlansCell,
    'c-16': PrayerTimesCell,
    'c-18': WelcomeCell,
    'c-19': SystemMessageCell,
    'c-20': AgentCell,
    'c-21': CustomAdCell,
    'c-22': CampaignCell,
    'c-25': GenericActionButtonsCells,
    'c-26': QuickActionsCell,
    'c-30': GenericCardsCell,
    'c-31': LastRechargesCell,
    'c-32': DataSharingCell,
    'c-95': ResetEpinCell,
    'c-96': LoyaltyCell,
    'c-97': TypingCell,
    'c-98': TTTCell,
    'c-99': SurveyCell
  }
}
</script>
