<!--suppress NonAsciiCharacters -->
<template>
  <div>
    <h6 class="animated fadeInDown" style="display: block; width: 100%">{{ title }}</h6>
    <p>{{ extraText }}</p>
    <div class="list-item">
      <div
        class="swiper-container"
        v-carousel="{ language, count: allButtons.length }"
        :style="allButtons.length > 3 ? 'height: 105px;' : ''"
        :dir="language === 'ar' ? 'rtl' : 'ltr'">
        <div class="swiper-wrapper">
          <div
            :style="
              allButtons.length > 3
                ? btn.value === 'login' || btn.value === 'login-ar'
                  ? 'width: 150px;'
                  : 'width: 100px;'
                : 'width: ' + (100 / allButtons.length - 1.4) + '%'
            "
            v-bind:key="idx"
            class="text-white swiper-slide animated help-item text-center"
            :ref="'hcell' + idx"
            v-on:click="onCardAction(btn)"
            v-for="(btn, idx) in allButtons">
            <i
              v-if="btn.icon"
              class="fa"
              aria-hidden="true"
              :class="[btn.icon, 'purple-outline-btn-i']"></i>
            <p :class="{ larger: language === 'ar' }">{{ btn.title | toCaps }}</p>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <!--    <div :dir="language === 'ar'? 'rtl': 'ltr'" @click="$store.dispatch('sendChatMessage', language === 'en'? 'I have an issue' : 'عندي مشكلة')" v-if="allButtons.length > 8" class="horizontal-btn">-->
    <!--      {{language === 'en'? 'I have an issue, i need help!' : 'أحتاج مساعدة!'}}-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Swiper from 'swiper/swiper-bundle.min'

export default {
  name: 'HelpCell',
  props: ['actions', 'title', 'extraText', 'attachments', 'language', 'helpInfo'],
  data() {
    return {}
  },
  methods: {
    ...mapActions('chatModule', ['sendChatMessage']),
    onCardAction(btn) {
      if (btn.type === 'imBack') this.sendChatMessage(btn.value)
      if (btn.type === 'openUrl') window.open(btn.value)
    },
    animateOpacityStaggered() {
      let vm = this
      for (let i = 0; i < this.allButtons.length; i++) {
        setTimeout(() => {
          vm.$refs['hcell' + i][0].setAttribute(
            'class',
            vm.$refs.hcell0[0].getAttribute('class') + ' ' + 'fadeInDown'
          )
        }, 275 * i)
      }
    }
  },
  directives: {
    carousel: {
      inserted: function (el, binding) {
        if (binding.value.count === 0) return
        // eslint-disable-next-line no-unused-vars
        const swiper = new Swiper(el, {
          spaceBetween: 10,
          freeMode: false,
          mousewheel: true,
          grabCursor: true,
          observer: true,
          pagination: {
            el: binding.value.count > 3 ? el.childNodes[2] : null,
            dynamicBullets: true
          },
          slidesPerView: 'auto'
        })
      }
    }
  },
  computed: {
    allButtons() {
      // let allButtons = []
      // for (let obj in this.helpInfo) {
      //   if (this.helpInfo.hasOwnProperty(obj)) allButtons.push(...this.helpInfo[obj].actions)
      // }
      // allButtons = allButtons.sort((a, b) => a.sequence - b.sequence)
      return this.actions
    }
  },
  filters: {
    toCaps: function (value) {
      if (!value) return ''
      return value.toUpperCase()
    }
  },
  mounted() {
    this.animateOpacityStaggered()
  }
}
</script>

<style scoped>
.help-item {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  height: 105px;
  cursor: pointer;
  opacity: 0;
}

.help-item:hover {
  background: #d02486;
}

.help-btn {
  display: block;
  position: relative;
  margin-top: 0.6rem;
}

p {
  font-size: 0.8em;
  /*color: #d02486;*/
  padding: 0 5px;
  line-height: 1;
  /*display: table-cell;*/
  vertical-align: middle;
}

.purple-outline-btn-i {
  position: relative;
  left: auto;
  top: auto;
  font-size: 1.6em;
  display: block;
  /*color: #d02486;*/
  padding: 18px 0;
}

.purple-outline-btn-ar-i {
  position: absolute;
  right: 25px;
  top: 10px;
  font-size: 1.7em;
  -webkit-transform: translate(50%, 0);
  -ms-transform: translate(50%, 0);
  transform: translate(50%, 0);
}

.text-right-imp {
  text-align: right !important;
}

.purple-outline-btn {
  min-height: auto;
  line-height: 2.2;
  padding: 0.35em 3.2em;
  text-align: left;
}

.larger {
  font-size: 1em;
}

.horizontal-btn {
  border: rgba(255, 255, 255, 0.65) 1px solid;
  border-radius: 5px;
  padding: 7px 0 5px;
  width: 100%;
  margin: 4px auto 0;
  max-width: 550px;
  color: white;
  cursor: pointer;
}
</style>
