<template>
  <div v-show="localActive"
       class="fixed z-50 inset-0 overflow-y-auto"
       aria-labelledby="modal-title"
       role="dialog"
       aria-modal="true">

    <div class="flex items-center flex-col justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">

      <!-- clicking outside should trigger cancel & close (if enabled) -->
      <div class="fixed inset-0 bg-black bg-opacity-60 transition-opacity"
           :class="overlayClasses"
           aria-hidden="true"
           @click="() => { if (canClickOutside) onClickCancel();}"/>

      <div :class="panelClasses"
           class="inline-block rounded-xl overflow-hidden shadow-xl transform transition-all my-4 align-middle max-w-lg w-full">
        <div class="bg-white dark:bg-gray-700 dark:text-white">
          <span v-if="canCancel"
                @click="onClickCancel"
                class="absolute right-2 top-2 cursor-pointer">
            <img class="inline-block m-2"
                 src="../assets/images/cross.svg"
                 alt="Cancel"/>
          </span>

          <component
              v-if="component"
              v-bind="props"
              v-on="events"
              :is="component"
              :can-cancel="canCancel"
              @close="close"
          />
          <template v-else-if="content">
            <div class="p-6"
                 v-html="content" />
          </template>
          <slot v-else
                name="default" />

        </div>

        <div class="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:px-6 flex flex-row-reverse"
             v-if="canConfirm || (canCancel && cancelLabel)">
          <z-button v-if="canConfirm"
                    class="ms-3"
                    @click="onClickConfirm">
            {{ confirmLabel }}
          </z-button>
          <z-button v-if="canCancel && cancelLabel"
                    borderless
                    class="bg-transparent bg-gray-200 no-underline"
                    @click="onClickCancel">
            {{ cancelLabel }}
          </z-button>
        </div>

      </div>

      <slot name="belowModal"/>
    </div>
  </div>
</template>

<script>
import ZButton from './ZButton'
import { removeElement } from '../utils/helpers'

export default {
  name: 'ZModal',
  components: { ZButton },
  props: {
    component: [Object, Function, String],
    content: [String, Array],
    events: Object,
    props: Object,
    hasModalCard: Boolean,
    active: {
      type: Boolean,
      default: () => false,
    },
    canCancel: Boolean,
    onCancel: {
      type: Function,
      default: () => {
      }
    },
    cancelLabel: {
      type: String,
      default: ''
    },
    canConfirm: Boolean,
    onConfirm: {
      type: Function,
      default: () => {
      }
    },
    confirmLabel: {
      type: String,
      default: 'OK'
    },
    canClickOutside: {
      type: Boolean,
      default: () => true,
    },
    programmatic: Boolean,
  },
  data: () => ({
    localActive: false,

    enter: ['ease-out', 'duration-300'],
    exit: ['ease-in', 'duration-200'],

    enterOverlayFrom: ['opacity-0'],
    enterOverlayTo: ['opacity-100'],

    exitOverlayFrom: ['opacity-100'],
    exitOverlayTo: ['opacity-0'],

    enterPanelFrom: ['opacity-0', 'translate-y-4', 'sm:translate-y-0', 'sm:scale-95'],
    enterPanelTo: ['opacity-100', 'translate-y-0', 'sm:scale-100'],

    exitPanelFrom: ['opacity-100', 'translate-y-0', 'sm:scale-100'],
    exitPanelTo: ['opacity-0', 'translate-y-4', 'sm:translate-y-0', 'sm:scale-95'],

    overlayClasses: [],
    panelClasses: []
  }),
  computed: {},
  watch: {
    active: {
      handler: function () {
        if (this.active) {
          this.enterAnimation()
        } else {
          this.exitAnimation()
        }
      }
    }
  },
  methods: {
    onClickConfirm() {
      this.onConfirm()
      this.close()
    },
    onClickCancel() {
      this.onCancel()
      this.close()
    },
    close() {
      this.$emit('update:active', false)

      if (this.programmatic) {
        this.exitAnimation()
      }
    },
    enterAnimation() {
      this.overlayClasses = [...this.enter, ...this.enterOverlayFrom]
      this.panelClasses = [...this.enter, ...this.enterPanelFrom]
      this.localActive = true
      const self = this
      setTimeout(() => {
        self.overlayClasses = [...self.enter, ...self.enterOverlayTo]
        self.panelClasses = [...self.exit, ...self.enterPanelTo]
      }, 0)
    },
    exitAnimation() {
      this.overlayClasses = [...this.exit, ...this.exitOverlayTo]
      this.panelClasses = [...this.exit, ...this.exitPanelTo]
      const self = this
      setTimeout(() => {
        self.localActive = false
        if (self.programmatic) {
          self.$destroy()
          removeElement(this.$el)
        }
      }, 200)
    }
  },
  beforeMount() {
    // Insert the Modal component in body tag
    // only if it's programmatic
    this.programmatic &&  this.$parent.$el.appendChild(this.$el)
  },
  mounted() {
    if (this.programmatic) this.enterAnimation()
    else this.localActive = this.active
  }
}
</script>
