<template>
  <div class="text-8xl sm:text-9xl" :style="styleVars">

    <div class="pie-wrapper">
      <div class="label flex flex-wrap flex-col content-center justify-center">
        <slot/>
      </div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="-1 -1 34 34">

          <circle cx="16" cy="16" r="15.9155"
                  class="progress-bar__background"/>

          <circle cx="16" cy="16" r="15.9155"
                  class="progress-bar__progress
                 js-progress-bar"/>
        </svg>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ZCircleProgress',
  components: {},
  computed: {
    styleVars() {
      return {
        '--circle-color': this.circleColor,
        '--progress-value': 100 - this.value,
        '--progress-bar-stroke-width': 1.9
      }
    }
  },
  props: {
    value: {
      type: Number,
      default: () => 100
    },
    circleColor: {
      type: String,
      default: () => 'rgba(76, 29, 149, 1)'
    }
  },
  data: () => ({
    localValue: 0
  })
}
</script>

<!--suppress CssUnresolvedCustomProperty -->
<style scoped>

.pie-wrapper {
  position: relative;
  width: 1em;
  height: 1em;
  margin: 0 auto;
}

.pie-wrapper .label {
  position: absolute;
  top: 0.52em;
  right: 0.4em;
  bottom: 0.4em;
  left: 0.4em;
  display: flex;
  background: none;
  border-radius: 50%;
  color: #7F8C8D;
  font-size: 0.15em;
  text-align: center;
  cursor: default;
  z-index: 2;
}

svg {
  height: var(--progress-bar-size);
  transform: rotate(-90deg);
  width: var(--progress-bar-size);
}

.progress-bar__background {
  fill: none;
  stroke: #e2eff0;
  stroke-width: var(--progress-bar-stroke-width);
}

/*noinspection ALL*/
.progress-bar__progress {
  fill: none;
  stroke: var(--circle-color);
  stroke-dasharray: 100 100;
  stroke-dashoffset: var(--progress-value);
  stroke-linecap: round;
  stroke-width: var(--progress-bar-stroke-width);
  transition: stroke-dashoffset 0.5s ease-in-out;
}
</style>
