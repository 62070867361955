<template>
  <label class="block text-sm font-medium">{{ getLabel }}
    <span class="relative">
        <select v-model="computedValue" @input="onInput" v-bind="$attrs"
                :class="[
                    {'border-danger': this.getType === 'danger'},
                    { [customClasses]: customClasses },
                    {'bg-gray-100 dark:bg-gray-800 dark:placeholder-gray-100 border border-gray-200 dark:border-gray-500' : !this.borderless && !customClasses,
                    'bg-white text-primary border-0' : this.borderless && !customClasses},
                    ]"
                class="mt-1 w-full me-6 px-3 py-2.5
                 rounded-md focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-400">
          <option v-if="placeholder" value="" disabled selected>{{ placeholder }}</option>
          <slot/>
        </select>
      </span>
  </label>
</template>

<script>
import formMixin from '../utils/mixins/formMixin'


export default {
  inheritAttrs: false,
  name: 'ZSelect',
  mixins: [formMixin],
  props: {
    value: [String, Number],
    placeholder: {
      type: String
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    customClasses: { type: String, default: '' }

  },
  computed: {
    computedValue: {
      get() {
        return this.localValue
      },
      set(value) {
        this.localValue = value
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value(val) {
      this.computedValue = val
    }
  },
  data() {
    return {
      localValue: ''
    }
  },
  methods: {
    onInput(event) {
      this.computedValue = event.target.value
    }
  },
  mounted() {
    this.localValue = this.value
  }
}
</script>

<style scoped>

[dir="rtl"] select {
  background-position: left 0.5rem center;
}
</style>
