<template>
  <div class="bg-white p-2 rounded-md text-left">
    <transition name="zoom" tag="div" mode="out-in">
      <div :key="1" v-if="cardState === cardStates.FindCountry">
        <input :placeholder="placeholderText()" v-model="countrySearchTerm" type="text" />
        <div class="grid-x" style="height: 220px; overflow: scroll; overflow-x: hidden">
          <div
            style="height: 50px"
            class="medium-6 large-4 cell"
            v-for="country in filteredCountries"
            v-bind:key="country.iso">
            <img
              :src="'https://www.kw.zain.com/documents/6389425/6398613/' + country.iso + '.png'" />
            <a
              class="black"
              :style="language !== 'ar' ? 'padding-right: 10px' : 'padding-left: 10px'"
              @click="searchCountry(country)">
              {{ language === 'ar' ? country.countryNameAR : country.countryNameEN }}
            </a>
          </div>
        </div>
      </div>
      <div :key="2" v-else-if="cardState === cardStates.CountryDetail">
        <img
          class="inline-block"
          :src="
            'https://www.kw.zain.com/documents/6389425/6398613/' +
            selectedCountry.selectedCountry.ISO +
            '.png'
          "
          alt="" />
        <h5 class="inline-block px-2 my-5">
          {{
            language === 'ar'
              ? selectedCountry.selectedCountry.CountryNameAR
              : selectedCountry.selectedCountry.CountryNameEN
          }}
        </h5>
        <div v-for="offer in selectedCountry.offers">
          <div class="grid grid-cols-2">
            <h6 class="rate-header col-span-2">
              <i style="color: #ffed00" class="fa fa-star" aria-hidden="true"></i>
              {{ offer.name }}
            </h6>
            <div
              v-for="localOffer in offer.offers"
              :key="localOffer.id"
              class="rateContainer flex w-full justify-between">
              <div class="display-inline-block">
                <p class="bold">{{ localOffer.Operator }}</p>
                <p class="text-xs">{{ localOffer.HandsetDisplayName }}</p>
              </div>
              <div>
                <p class="font-bold">{{ localOffer.RetailOffer.split(' ')[0] }}</p>
                <p>{{ language === 'ar' ? 'في الإسبوع' : 'Per Week' }}</p>
              </div>
            </div>
          </div>
          <br />
        </div>
        <br />
        <div class="grid grid-cols-2">
          <h6 class="col-span-2 rate-header">
            {{ language === 'ar' ? 'تجوال المكالمات والرسائل النصية' : 'Voice & SMS Roaming' }}
          </h6>

          <div class="rateContainer">
            <p class="font-bold">
              {{ Number.parseFloat(currentZone.CallsToSameCountry).toFixed(3) }}
            </p>
            <p>{{ language === 'ar' ? 'لنفس الدولة' : 'To Same Country' }}</p>
          </div>
          <div class="rateContainer">
            <p class="font-bold">{{ Number.parseFloat(currentZone.CallsToKuwait).toFixed(3) }}</p>
            <p>{{ language === 'ar' ? 'للكويت والخليج العربي' : 'To Kuwait & GCC' }}</p>
          </div>
          <div class="rateContainer">
            <p class="font-bold">
              {{ Number.parseFloat(currentZone.CallsToAfricaMENA).toFixed(3) }}
            </p>
            <p>{{ language === 'ar' ? 'لأفريقيا والشرق الأوسط' : 'To Africa & MENA' }}</p>
          </div>
          <div class="rateContainer">
            <p class="font-bold">
              {{ Number.parseFloat(currentZone.CallsToAmericasEuropeAsia).toFixed(3) }}
            </p>
            <p>{{ language === 'ar' ? 'لأمريكا وأوروبا وآسيا' : 'To Americas, Europe & Asia' }}</p>
          </div>
          <div class="rateContainer">
            <p class="font-bold">{{ Number.parseFloat(currentZone.RestOfTheWorld).toFixed(3) }}</p>
            <p>{{ language === 'ar' ? 'لباقي دول العالم' : 'Rest Of The World' }}</p>
          </div>
          <div class="rateContainer">
            <p class="font-bold">{{ Number.parseFloat(currentZone.IncomingCalls).toFixed(3) }}</p>
            <p>{{ language === 'ar' ? 'إستقبال المكالمات' : 'Incoming Calls' }}</p>
          </div>
          <div class="rateContainer">
            <p class="font-bold">{{ Number.parseFloat(currentZone.SendingSMS).toFixed(3) }}</p>
            <p>{{ language === 'ar' ? 'إرسال الرسائل النصية' : 'Sending SMS' }}</p>
          </div>
        </div>
        <br />
        <!--<small style="display: block"><strong>RoamingFooterMessage}}</strong></small>-->
        <div class="flex md:items-center md:space-x-2 md:space-y-0 space-y-2 md:flex-row flex-col ">
          <z-button @click="addService(false)" class="purple-outline-btn inverted margin-bottom-0"
            >{{ language === 'ar' ? 'تفعيل التجوال' : 'Activate Roaming' }}
          </z-button>

          <z-button
            v-if="selectedCountry.offers && selectedCountry.offers.length"
            @click="addService(true)"
            class="purple-outline-btn inverted margin-bottom-0"
            >{{ language === 'ar' ? 'تفعيل التجوال بلس' : 'Activate Roaming Plus' }}
          </z-button>
          <a
            target="_blank"
            @click="$ga.event('ExternalLinks', 'RoamingTermsAndConditions', language)"
            :href="termConditionHref()"
            >{{ language === 'ar' ? 'تطبق الشروط والأحكام' : 'Terms and Conditions' }}</a
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ZButton from '../../zain-component-library/src/lib-components/ZButton'

export default {
  name: 'selectedCountryCell',
  components: { ZButton },
  props: ['roamingRates', 'localisationKeys', 'language'],
  data() {
    return {
      countrySearchTerm: '',
      selectedCountry: '',
      cardState: 0,
      cardStates: {
        FindCountry: 0,
        CountryDetail: 1
      }
    }
  },
  computed: {
    currentZone() {
      if (!this.selectedCountry) return false
      return this.selectedCountry.zones
    }
  },
  methods: {
    ...mapActions('chatModule', ['sendChatMessage']),
    termConditionHref() {
      if (this.language === 'en') {
        return 'https://www.kw.zain.com/en/roaming-terms-and-conditions-'
      } else {
        return 'https://www.kw.zain.com/ar/roaming-terms-and-conditions-'
      }
    },
    addService(plus) {
      let text = this.language === 'en' ? 'Add Roaming' : 'اضافه تجوال'
      if (plus) {
        text = this.language === 'en' ? 'Add Roaming Plus' : 'اضافه تجوال بلس'
      }

      this.sendChatMessage(text)
    }
  },
  created() {
    if (typeof this.roamingRates.selectedCountry !== 'undefined') {
      this.selectedCountry = this.roamingRates
      this.cardState = this.cardStates.CountryDetail
    } else {
      this.selectedCountry = ''
      this.cardState = this.cardStates.FindCountry
    }
  }
}
</script>

<style type="text/scss" scoped>
.rateContainer {
  background: #f3f3f3;
  padding: 5px;
  border: 1px rgb(255, 255, 255) solid;
  font-size: 0.8rem;
}
.rateContainer p {
  margin: 0;
}
.rate-header {
  background-color: rgb(182, 97, 182);
  padding: 5px;
  color: white;
  margin: 0;
  border: 1px rgb(255, 255, 255) solid;
}
</style>
