<template>
  <div class="text-center py-3 bg-white rounded-md shadow-md">
    <img alt="Welcome" src="../../assets/ChatBot-Icon-s.png" class="w-10 mx-auto my-3" />
    <h5 style="direction: rtl">مرحبا، أنا <b>zBot</b>. كيف يمكنني مساعدتك؟</h5>
    <h5>Hello, I am <b>zBot</b>. How may I help you?</h5>
  </div>
</template>
<script>
export default {
  name: 'Welcome'
}
</script>
