<template>
  <div class="bg-white rounded-md overflow-hidden">
    <transition name="zoom" tag="div" mode="out-in">
      <div class="w-full main-branchcell relative">
        <ZLoading v-if="isLoading" :active="isLoading" :is-full-page="false" />
        <div v-else>
          <div :key="1" class="p-2" v-if="cardState === cardStates.FindBranch">
            <div class="flex flex-row space-x-3 justify-evenly mt-1 mb-3">
              <z-select
                :placeholder="$t('Governorate', language, strings)"
                v-model="selectedGovernate"
                @input="
                  () => {
                    selectedArea = ''
                    selectedType = ''
                  }
                ">
                <option v-for="gov in branches.locations" :key="gov.title">
                  {{ gov.title }}
                </option>
              </z-select>
              <z-select :placeholder="$t('Area', language, strings)" v-model="selectedArea">
                <option v-for="area in areas" :key="area">
                  {{ area }}
                </option>
              </z-select>
              <z-select :placeholder="$t('Type', language, strings)" v-model="selectedType">
                <option v-for="cat in branches.types" :value="cat" :key="cat">
                  {{ $t(cat, language, strings) }}
                </option>
              </z-select>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-3 results-list">
              <!--<p v-if="filtered.length === 0">No Results Found</p>-->
              <a
                v-for="(item, index) in filtered"
                v-bind:key="'s' + index"
                class="small-12 medium-6 large-4 cell results-item"
                @click="selectBranch(item)">
                <img
                  alt="BranchIcon"
                  class="mx-5"
                  :src="getBranchIcon(item.Type)"
                  aria-hidden="true" />
                &nbsp;&nbsp;&nbsp;{{ item.Name !== '' ? item.Name : getDefaultName(item.Type) }}
              </a>
            </div>
          </div>
          <div :key="3" v-else-if="cardState === cardStates.BranchDetail">
            <div
              v-if="selectedItem"
              :id="id"
              ref="map"
              class="map"
              style="height: 267px"
              v-gmap="{ latLong: latLong }"></div>
            <div class="p-4 text-left">
              <a @click="findBranches" class="underline text-blue-700 cursor-pointer">
                Find Another Location
              </a>
              <h4 class="my-3">
                <img
                  class="h-6 inline-block pe-2"
                  alt="BranchIcon"
                  :src="getBranchIcon(selectedItem.type)"
                  aria-hidden="true" />
                <strong>{{ selectedItem.name }}</strong>
              </h4>
              <strong>
                <small>Working Hours</small>
              </strong>
              <h6 v-for="timing in selectedItem.working_hours">
                {{ timing.days }}: {{ timing.hours }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ZSelect, ZLoading } from '@/zain-component-library/src/lib-components'
import config from '@/config'
import mixins from '@/mixins'
const axios = require('axios').default

export default {
  components: { ZLoading, ZSelect },
  name: 'BranchCell',
  props: ['branchData', 'language', 'entities'],
  mixins: [mixins],
  data() {
    return {
      localisationKeys: {},
      cardStates: {
        FindBranch: 0,
        BranchResults: 1,
        BranchDetail: 2
      },
      cardState: 0,
      selectedGovernate: '',
      selectedArea: '',
      selectedType: 'Branches',
      selectedItem: '',
      id: null,
      latLong: { lat: 0, long: 0 },
      typeMap: {
        Branches: 'الفروع',
        Kiosks: 'أجهزة الدفع',
        Workshops: 'ورش التصليح'
      },
      localBranches: {
        en: null,
        ar: null
      },
      strings: {
        Governorate: {
          en: 'Governorate',
          ar: 'محافظة'
        },
        Type: {
          en: 'Type',
          ar: 'نوع'
        },
        Area: {
          en: 'Area',
          ar: 'المنطقة'
        },
        Kiosks: {
          en: 'Kiosks',
          ar: 'أجهزة الدفع'
        },
        Workshops: {
          en: 'Workshops',
          ar: 'ورش'
        },
        Distributors: {
          en: 'Distributors',
          ar: 'موزعين'
        },
        Branches: {
          en: 'Branches',
          ar: 'الأفرع'
        }
      },
      isLoading: false
    }
  },
  computed: {
    branches() {
      return this.localBranches[this.language]
    },
    areas() {
      return this.selectedGovernate
        ? this.branches.locations.find((x) => x.title === this.selectedGovernate).areas
        : []
    },
    filtered() {
      let items = JSON.parse(JSON.stringify(this.branches.items))

      if (this.selectedGovernate) {
        items = items.filter((x) => x.Address.Governorate === this.selectedGovernate)
      }

      if (this.selectedType) {
        items = items.filter((x) => x.Type === this.selectedType)
      }

      if (this.selectedArea) {
        items = items.filter((x) => x.Address.Area === this.selectedArea)
      }

      //items that has no name pushed down the result list
      items = items.sort((x1, x2) => {
        if (x1.Name === '' && x2.Name === '') return 0
        if (x1.Name === '' && x2.Name !== '') {
          return 1
        } else {
          return -1
        }
      })

      return items
    }
  },
  methods: {
    selectBranch(item) {
      this.selectedItem = {
        name: item.Name,
        latitude: Number.parseFloat(item.Latitude),
        longitude: Number.parseFloat(item.Longitude),
        type: item.type || 'Branches'
      }
      if (item.hasOwnProperty('WorkingTime')) {
        // noinspection JSPrimitiveTypeWrapperUsage
        this.selectedItem.working_hours = item.WorkingTime?.Days?.map((x) => {
          return {
            days:
              x.attributes.from === x.attributes.to
                ? x.attributes.from
                : x.attributes.from + ' - ' + x.attributes.to,
            hours: x.Timing[0].attributes.from + ' - ' + x.Timing[0].attributes.to
          }
        })
      }
      this.updateCardState(this.cardStates.BranchDetail)
      this.setupMap()
    },
    findBranches() {
      this.cardState = this.cardStates.FindBranch
    },
    setupMap() {
      this.id = 'map' + this._uid

      this.latLong = {
        lat: this.selectedItem.latitude,
        lng: this.selectedItem.longitude
      }
    },
    updateCardState(state) {
      this.cardState = state
    },
    getBranchIcon(type) {
      if (type === 'Branches' || type === 'الفروع') return require('../../assets/branch.png')
      if (type === 'Kiosks' || type === 'أجهزة الدفع') return require('../../assets/kiosk.png')
      if (type === 'Workshops' || type === 'ورش التصليح')
        return require('../../assets/workshops.png')

      return require('../../assets/branch.png')
    },
    getDefaultName(type) {
      if (type === 'Branches' || type === 'الفروع') return this.language === 'en' ? 'Branch' : 'فرع'
      if (type === 'Kiosks' || type === 'أجهزة الدفع')
        return this.language === 'en' ? 'Kiosk' : 'جهاز دفع'
      if (type === 'Workshops' || type === 'ورش التصليح')
        return this.language === 'en' ? 'Workshop' : 'ورشة'

      return type
    }
  },
  async mounted() {
    this.isLoading = true
    this.localBranches[`${this.language}`] = (
      await axios.get(`${config.value('botChannelsHost')}/utils/sales-channels/${this.language}`)
    ).data

    if (this.branchData) {
      if (this.branchData?.branch) {
        this.selectBranch(this.branchData?.branch)
      }

      if (this.branchData?.entity && this.branchData?.entity?.entity === 'Location Type') {
        this.selectedType = this.branchData?.entity?.option
      }
    }
    this.isLoading = false
  },
  directives: {
    gmap: {
      inserted: function (el, bindings) {
        window.setTimeout(() => {
          let GoogleMapsLoader = require('google-maps')
          GoogleMapsLoader.KEY = 'AIzaSyA1w_PqCQebjYUn-X8U9f9-JmvwmNv9v7w'
          GoogleMapsLoader.load(function (google) {
            let map = new google.maps.Map(el, {
              center: bindings.value.latLong,
              zoom: 14
            })
            // eslint-disable-next-line
            new google.maps.Marker({
              position: bindings.value.latLong,
              map: map,
              title: 'map'
            })
          })
        }, 200)
      }
    }
  }
}
</script>

<style type="text/css" scoped>
/* Always set the map height explicitly to define the size of the div
     * element that contains the map. */
.map {
  height: 100%;
  min-height: 200px;
  min-width: 230px;
}

.main-branchcell {
  min-height: 224px;
}

.results-list {
  max-height: 224px;
  overflow-y: scroll;
  margin: 0;
  list-style: none;
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
}

.results-item:hover,
.results-item:focus {
  color: #ffffff;
  background-color: #d02486;
}

.results-item {
  padding: 10px;
  border-radius: 5px;
  text-overflow: ellipsis;
  border-color: #d02486;
  border-width: 2px;
  border-style: solid;
  line-height: 1.2;
  text-align: left;
  color: #d02486;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  font-weight: bold;
  max-height: 50px;
}

.results-item img {
  width: 23px;
}
</style>
