<template>
  <div class="inline-flex flex-col justify-center items-center">
    <span class="font-semibold pb-2 text-xs text-gray-500">{{ labelTop }}</span>
    <div class="relative pt-1">
      <div
          :class="classes">
        <div :style="{height: `${localValue}%`, transition: 'height 1s'}" :class="`w-full bg-${mainColor}`"/>
        <div v-if="secondaryValue > 0"
             :style="{height: `${secondaryValue}%`, transition: 'height 1s'}"
             :class="`w-full rounded-b-xl bg-${secondaryColor}`"/>
      </div>
    </div>
    <span v-if="labelBottom" class="font-bold text-sm">{{ labelBottom }}</span>
    <span v-if="labelBottomSub" class="font-bold text-xs text-purple-800 dark:text-purple-500 bg-purple-100 px-2 py-0.5 rounded-lg">{{ labelBottomSub }}</span>
  </div>
</template>

<script>
export default {
  name: 'ZProgressBarVertical',
  props: {
    value: {
      type: Number,
      default: () => 20
    },
    mainColor: {
      type: String,
      default: () => 'secondary'
    },
    secondaryValue: {
      type: Number,
      default: () => 0
    },
    secondaryColor: {
      type: String,
      default: () => 'yellow-500'
    },
    labelTop: {
      type: String,
      default: () => 'Top Label'
    },
    labelBottom: {
      type: String,
      default: () => 'Bottom Label'
    },
    labelBottomSub: {
      type: String,
    },
    size: {
      type: String,
      default: () => 'medium'
    },
  },
  computed: {
    classes() {
      let c = `transform rotate-180 overflow-hidden w-3 mb-4 text-xs flex flex-col
      rounded-xl bg-gray-200 dark:bg-gray-800 dark:bg-opacity-60`
      switch (this.size) {
        case 'medium': {
          c = c.concat(' h-32')
          break
        }
        case 'large': {
          c = c.concat(' h-44')
          break
        }
      }
      return c
    }
  },
  data: () => ({
    localValue: 0
  }),
  watch: {
    value(val) {
      this.$forceNextTick(() => {
        this.localValue = val
      })
    }
  },
  mounted() {
    this.$forceNextTick(() => {
      this.localValue = this.value
    })
  }
}
</script>

<style scoped>

</style>
