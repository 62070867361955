<template>
    <transition :name="transitionName"
                v-on:before-enter="$parent.isTransitioning = true"
                v-on:after-enter="$parent.isTransitioning = false">
      <div v-if="isActive" class="flex-shrink-0">
        <slot/>
      </div>
    </transition>
</template>

<script>
export default {
  name: 'ZTabItem',
  props: {
    label: String,
    icon: String,
    sequence: Number
  },
  data: () => ({
    tabIndex: -1,
    transitionName: ''
  }),
  computed: {
    isActive() {
      return this.$parent.activeTabInternal === this.tabIndex
    }
  },
  methods: {
    activate(oldIndex) {
      this.transitionName = this.tabIndex < oldIndex
          ? 'slide-next'
          : 'slide-prev'
    },
    deactivate(newIndex) {
      this.transitionName = this.tabIndex > newIndex
          ? 'slide-next'
          : 'slide-prev'
    }
  },
  mounted() {
    this.tabIndex = this.$parent.registerItem(this) - 1
  },
  beforeDestroy() {
    this.$parent.unregisterItem(this)
  }
}
</script>
