<template>
  <div>
    <div v-if="isLoading">
      <p>{{ language === 'en' ? 'Logging in...' : 'جاري التحميل...' }}</p>
      <i class="fa fa-refresh loading-spinner"></i>
    </div>
    <div class="text-center" v-else-if="authed">
      <h5>
        {{ language === 'en' ? 'Welcome' : 'مرحبا' }}
        <strong>
          <i style="font-size: 0.9em; line-height: 1.1; color: #2fc4a8" class="fa fa-user"></i>
          {{ user.username }}</strong
        >
      </h5>
      <h4 class="text-center">{{ $store.state.user.userData.msisdn }}</h4>
      <a style="text-decoration: underline" @click="logoutRequest">{{
        language === 'en' ? 'Change User' : 'تغير المستخدم'
      }}</a>
    </div>
    <div v-else-if="!authed">
      <form @submit.prevent="loginRequest">
        <p>{{ cell_title() }}</p>
        <small v-if="errors.login" class="red display-block">{{ errors.login.error }}</small>
        <small v-if="error" class="red display-block">{{ error }}</small>
        <label :style="language === 'ar' ? 'direction: rtl;' : ''"
          >{{ language === 'en' ? 'Username' : 'اسم المستخدم' }}
          <input
            autocomplete="username"
            title="username"
            :disabled="isLoading"
            required
            v-model="username"
            type="text" />
        </label>
        <label
          >{{ language === 'en' ? 'Password' : 'كلمة المرور' }}
          <input
            autocomplete="current-password"
            title="password"
            @keyup.enter="loginRequest"
            :disabled="isLoading"
            required
            v-model="pass"
            type="password" />
        </label>
        <a
          href="https://chatbase.com/r?api_key=1dbed256-a7a1-40e3-a073-b1e1beb6b27e&platform=emulator&url=https://www.kw.zain.com/en/forgot-password"
          target="_blank"
          style="display: block; text-decoration: underline; padding-bottom: 5px"
          >{{ language === 'en' ? 'Forgot Password?' : 'نسيت كلمة المرور؟' }}
        </a>
        <button type="submit" :disabled="isLoading" class="purple-outline-btn inverted">
          {{ language === 'en' ? 'Login' : 'دخول' }}
        </button>
        <a
          href="https://chatbase.com/r?api_key=1dbed256-a7a1-40e3-a073-b1e1beb6b27e&platform=emulator&url=https://www.kw.zain.com/en/registration"
          target="_blank"
          :disabled="isLoading"
          class="purple-outline-btn inverted"
          >{{ language === 'en' ? 'Register' : 'حساب جديد' }}
        </a>
        <div v-if="isLoading">
          <i class="fa fa-refresh loading-spinner"></i>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'LoginCell',
  props: ['userInfo', 'localisationKeys', 'language', 'intent'],
  data() {
    return {
      authed: false,
      username: '',
      pass: '',
      staySignedIn: false,
      error: '',
      loading: false,
      cell_title: function () {
        if (this.localisationKeys) {
          return this.localisationKeys.text
        } else return ''
      }
    }
  },
  mounted() {
    // this.username = this.user.username
    this.authed = this.user.authenticated && !this.isSimLoggedIn
  },
  computed: {
    ...mapState({
      isSimLoggedIn: (state) => state.isSimLoggedIn,
      user: function (state) {
        if (!state.user.authenticated) {
          this.authed = false
        }
        return state.user
      },
      errors: (state) => state.errors,
      isLoading: function (state) {
        if (this.loading && !state.isLoading) {
          this.loading = false
          if (state.user.authenticated) {
            this.authed = true
            this.pass = ''
          }
          return false
        } else return this.loading
      }
    })
  },
  methods: {
    ...mapActions(['login', 'logout']),
    loginRequest() {
      this.error = ''
      if (!this.username || !this.pass) {
        this.error = 'All Fields are required'
        return
      }
      this.loading = true
      this.login({
        username: this.username,
        password: this.pass,
        locale: this.language,
        intent: this.intent
      })
    },
    logoutRequest() {
      this.logout()
      this.authed = false
      this.pass = ''
    }
  }
}
</script>

<style scoped>
.loading-spinner {
  animation: spinIt 1.5s infinite linear;
}

@keyframes spinIt {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
</style>
