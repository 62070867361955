<template>
  <div class="p-2 bg-white rounded-md text-left shadow-md">
    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-white w-full my-1 text-center">
      {{ $t('Usage', language, strings) }}
      <span class="font-bold">({{ channelData.userInfo.msisdn }})</span>
    </h3>

    <div v-for="(usageInfo, index) in usages" :key="index">
      <small :dir="language === 'ar' ? 'rtl' : 'ltr'">{{
        language === 'ar' ? usageInfo.serviceCategoryAr : usageInfo.serviceCategory
      }}</small>
      <br />
      <div class="flex justify-between my-0.5">
        <div>
          <h4 v-if="usageInfo.isUnlimited" class="inline-block">
            <span class="text-xl font-semibold">{{ $t('Unlimited', language, strings) }}</span>
          </h4>
          <h4 v-else style="display: inline-block">
            <span class="text-xl font-semibold">{{ usageInfo.used }}</span
            >/{{ usageInfo.threshold }}
            {{
              strings[`${usageInfo.unit}`] ? $t(usageInfo.unit, language, strings) : usageInfo.unit
            }}
          </h4>
        </div>
        <i
          :class="getServiceIconClasses(usageInfo.service)"
          class="fa fa-2x pull-right"
          aria-hidden="true"></i>
      </div>
      <div
        v-if="!usageInfo.isUnlimited"
        class="round progress"
        role="progressbar"
        aria-valuemax="100">
        <div
          :class="usageInfo.unit === 'KB' ? 'green-bg' : 'purple-bg'"
          class="progress-meter"
          :style="{ width: (usageInfo.used / usageInfo.threshold) * 100 + '%' }"></div>
      </div>
    </div>
    <div class="medium-6 cell" v-if="usages.length === 0">
      {{ $t('No Usage Info Available', language, strings) }}
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins'

export default {
  name: 'UsageCell',
  props: ['usageList', 'language', 'channelData'],
  mixins: [mixins],
  data() {
    return {
      strings: {
        Usage: {
          en: 'Usage',
          ar: 'استخدامي'
        },
        Unlimited: {
          en: 'Unlimited ∞',
          ar: 'غير محدود ∞'
        },
        'No Usage Info Available': {
          en: 'No Usage Info Available',
          ar: 'لا توجد معلومات استخدام متاحة'
        },
        Minutes: {
          en: 'Minutes',
          ar: 'دقائق'
        }
      }
    }
  },
  computed: {
    usages() {
      return this.usageList
    }
  },
  methods: {
    getServiceIconClasses(service) {
      if (service === 'Data') return 'fa-wifi green'
      if (service === 'SMS') return 'fa-comments pink'
      if (service === 'MMS') return 'fa-comments green'
      let v = service.toLowerCase().includes('voice') || service.toLowerCase().includes('minutes')
      if (v) return 'fa-phone purple'
    }
  }
}
</script>
<style>
.progress {
  height: 0.5rem;
  margin-bottom: 0.6rem;
  background-color: #e3e3e3;
}

.round,
.round .progress-meter {
  border-radius: 10px;
}

.progress-meter {
  transition: width 1.25s ease-in-out;
}

.purple-bg {
  background-color: #b46bcd;
}

.green-bg {
  background-color: #7bc440;
}

.purple {
  color: #8e45a8;
}

.pink {
  color: #d02486;
}

.green {
  color: #7bc440;
}

.progress {
}

.progress-meter {
  position: relative;
  display: block;
  width: 0;
  height: 100%;
}
</style>
