<template>
    <div class="flex flex-col items-center py-1 space-y-4 justify-around animate-pulse min-h-full">
      <div class="h-4 bg-gray-200 rounded w-3/4"></div>
      <div class="h-4 bg-gray-200 rounded w-3/4"></div>
      <div class="h-4 bg-gray-200 rounded w-3/4"></div>
  </div>
</template>

<script>
export default {
  name: 'ZSkeleton'
}
</script>

<style scoped>

</style>