<template>
  <div class="p-2 bg-white rounded-md text-left shadow-md">
    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-white w-full my-1 text-center">
      {{ $t('Internet Sharing Line', language, strings) }}
      <span class="font-bold" v-if="channelData.userInfo.msisdn !== null"
        >({{ channelData.userInfo.msisdn }})</span
      >
    </h3>

    <div class="medium-6 cell" v-if="isDataSharing === false">
      {{ $t('DataShareCapNoLinesFound', language, strings) }}
    </div>

    <div v-else class="medium-6 cell">
      <h4 class="text-md font- text-gray-900 dark:text-white w-full my-0.5">
        {{ $t('MasterLineTitle', language, strings) }}
      </h4>
      <UsageComponent
        :msisdn="channelData.userInfo.msisdn"
        :language="language"
        :used="dataSharingData.TotalUsage.Used"
        :threshold="dataSharingData.TotalUsage.Threshold"
        :unit="dataSharingData.TotalUsage.Unit"
        :is-unlimited="dataSharingData.TotalUsage.isUnlimited" />

      <hr class="my-3" />
      <h4 class="text-md font- text-gray-900 dark:text-white w-full my-0.5">
        {{ $t('DependantLineTitle', language, strings) }}
      </h4>
      <UsageComponent
        v-for="(dependantInfo, index) in dataSharingData.DependentsInfo.DependentInfo"
        :key="index"
        class="mb-2"
        :msisdn="dependantInfo.Msisdn"
        :nickname="dependantInfo.nickname"
        :language="language"
        :used="dependantInfo.Used"
        :threshold="dependantInfo.Threshold"
        :unit="dependantInfo.Unit"
        :is-unlimited="dependantInfo.isUnlimited" />

      <div class="p-3 flex flex-wrap gap-3 justify-center">
        <z-button
          class="flex-1"
          @click="onClickChangeCap"
          size="small"
          style="min-width: 80px"
          outlined
          >{{ $t('ChangeCap', language, strings) }}</z-button
        >
        <z-button
          class="flex-1"
          @click="onClickChangeNickname"
          size="small"
          style="min-width: 80px"
          outlined
          >{{ $t('ChangeNickname', language, strings) }}</z-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins'
import UsageComponent from '@/components/cells/DataSharingCell/UsageComponent'
import { ZButton } from '@/zain-component-library/src'
import { mapActions } from 'vuex'

export default {
  props: ['isDataSharing', 'dataSharingData', 'language', 'channelData'],
  name: 'DataSharingCell',
  components: { UsageComponent, ZButton },
  mixins: [mixins],
  data() {
    return {
      strings: {
        DataShareCapNoLinesFound: {
          en: 'No Internet Sharing Lines Found',
          ar: 'لم يتم العثور على خطوط مشاركة على الإنترنت'
        },
        'Internet Sharing Line': {
          en: 'Internet Sharing Line',
          ar: 'خط مشاركة الإنترنت'
        },
        Unlimited: {
          en: 'Unlimited ∞',
          ar: 'غير محدود ∞'
        },
        ChangeCap: {
          en: 'Change Cap',
          ar: 'تغيير الغطاء'
        },
        ChangeNickname: {
          en: 'Change Nickname',
          ar: 'تغيير الإسم'
        },
        MasterLineTitle: {
          en: "Master Line's Usage",
          ar: 'استخدام الخط الرئيسي'
        },
        DependantLineTitle: {
          en: "Dependant Lines' Usages",
          ar: 'استخدامات الخطوط التابعة'
        }
      }
    }
  },
  methods: {
    ...mapActions(['chatModule/sendChatMessage']),
    onClickChangeCap() {
      let text = this.$t('ChangeCap', this.language, this.strings)
      this.$store.dispatch('chatModule/sendChatMessage', text)
    },
    onClickChangeNickname() {
      let text = this.$t('ChangeNickname', this.language, this.strings)
      this.$store.dispatch('chatModule/sendChatMessage', text)
    }
  }
}
</script>

<style>
.progress {
  height: 0.5rem;
  margin-bottom: 0.6rem;
  background-color: #e3e3e3;
}

.round,
.round .progress-meter {
  border-radius: 10px;
}

.progress-meter {
  transition: width 1.25s ease-in-out;
}

.purple-bg {
  background-color: #b46bcd;
}

.green-bg {
  background-color: #7bc440;
}

.purple {
  color: #8e45a8;
}

.pink {
  color: #d02486;
}

.green {
  color: #7bc440;
}

.progress {
}

.progress-meter {
  position: relative;
  display: block;
  width: 0;
  height: 100%;
}
</style>
