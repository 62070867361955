<template>
  <label class="inline-block">
    <span v-if="$slots.default" class="text-black font-medium dark:text-white me-2">
     <slot/>
    </span>
    <span class="relative inline-block align-middle select-none"
          :class="[{'w-10': size === 'small'}, {'w-14': size === 'medium'}]">
      <span
          class="block overflow-hidden rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary focus:ring-opacity-50"
          ref="label" @keydown.prevent.enter.space="$refs.label.click()"
          :class="[{'bg-gray-400': !computedValue, 'bg-secondary': computedValue}, {'h-6': size === 'small'}, {'h-8': size === 'medium'}]">
        <input type="checkbox" name="toggle" v-model="computedValue" @input="onToggle" @click.stop
               :class="[{'right-4.5': !computedValue && size === 'small','right-6.5': !computedValue && size === 'medium','right-0.5': computedValue},
               {'w-5 h-5': size === 'small'},
               {'w-7 h-7': size === 'medium'}]"
               class="outline-none focus:outline-none top-0.5 duration-200 ease-in absolute block rounded-full bg-white appearance-none cursor-pointer text-white border-transparent"/>
      </span>
    </span>

  </label>
</template>

<script>
export default {
  name: 'ZToggle',
  props: {
    value: {
      type: Boolean
    },
    size: {
      // @values small/medium
      type: String,
      default: () => 'medium'
    }
  },
  data: () => ({
    localValue: false,
    uuid: null
  }),
  methods: {
    onToggle(val) {
      this.$emit('input', val.target.checked)
    }
  },
  watch: {
    value(val) {
      this.localValue = val
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.localValue
      },
      set(val) {
        this.localValue = val
        this.$emit('input', val)
      }
    },
  },
  mounted() {
    this.localValue = this.value
  },
  beforeCreate: function () {
    this.uuid = Math.random().toString(36).substr(2, 12)
  }
}
</script>

<style scoped>

</style>
