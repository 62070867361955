<template>
  <div>
    <a target="_blank" href="https://zain.to/5gchatbot"
      ><img src="../../assets/5g-banner.png" alt="5g"
    /></a>
  </div>
</template>

<script>
export default {
  name: 'CustomAdCell',
  props: ['language']
}
</script>

<style scoped>
img {
  width: 100%;
  margin: 0 auto 10px;
  border-radius: 15px;
  box-shadow: 0 6px 20px 10px rgba(0, 0, 0, 0.1);
  max-width: 410px;
  /*max-height: 410px;*/
  /*object-fit: contain;*/
}

div {
  text-align: center;
}
</style>
