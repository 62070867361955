<template>
  <div class="bg-white dark:bg-gray-700 dark:text-white rounded-xl rounded-lg text-center flex-col"
       :class="{'overflow-hidden': overflow === 'hidden', [shadow]: true}">
    <div v-if="$slots.header" class="px-4 py-5 text-lg leading-6 font-medium text-left text-gray-900 dark:text-white">
      <slot name="header" />
    </div>
    <div :class="{'border-t border-gray-200': this.$slots.header}">
      <slot />
    </div>
    <div v-if="$slots.footer" class="text-sm font-semibold text-left text-purple-900 dark:text-purple-300 bg-purple-200 dark:bg-gray-800 align-middle">
      <slot name="footer" />
    </div>

  </div>
</template>

<script>
export default {
  props: {
    overflow: {
      type: String,
      default: 'hidden'
    },
    shadow: {
      type: String,
      default: 'shadow-xl'
    }
  },
  name: 'ZCard'
}
</script>

<style scoped>

</style>
