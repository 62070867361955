// Import vue components
import * as components from './lib-components/index'
import forceNextTick from './utils/plugins/forceNextTick'
import * as ModalP from './utils/modal'

// install function executed by Vue.use()
const install = function installZainComponentLibrary(Vue) {
  Object.entries(components).forEach(([componentName, component]) => {
    Vue.component(componentName, component)
  })

  Vue.prototype.$uuid = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (window.crypto || window.msCrypto).getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
  Vue.$forceNextTick = forceNextTick
  Vue.prototype.$forceNextTick = forceNextTick
  ModalP.default.install(Vue)
}

// Create module definition for Vue.use()
export default install

// To allow individual component use, export components
// each can be registered via Vue.component()
export * from './lib-components/index'
export { ModalProgrammatic } from './utils/modal'
